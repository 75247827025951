import { createSlice } from "@reduxjs/toolkit";
import {
    getCommonInitialState,
    getCommonStart,
    getCommonSuccess,
    getCommonFailure,
} from "../utils";

const initialState = {
    paymentList: getCommonInitialState(),
    paymentStatus: getCommonInitialState(),
    paymentBulkAction: getCommonInitialState(),
    paymentDelete: getCommonInitialState(),
    paymentView: getCommonInitialState(),
    paymentSave: getCommonInitialState(),
    billingAccounts: getCommonInitialState(),
};

const PaymentSlice = createSlice({
    name: "payment",
    initialState,
    reducers: {

        //payment List
        paymentListStart: (state) => { state.paymentList = getCommonStart() },
        paymentListSuccess: (state, action) => { state.paymentList = getCommonSuccess(action.payload) },
        paymentListFailure: (state, action) => { state.paymentList = getCommonFailure(action.payload) },

        billingAccountsStart: (state) => { state.billingAccounts = getCommonStart() },
        billingAccountsSuccess: (state, action) => { state.billingAccounts = getCommonSuccess(action.payload) },
        billingAccountsFailure: (state, action) => { state.billingAccounts = getCommonFailure(action.payload) },

        //Change payment status
        paymentStatusStart: (state, action) => { state.paymentStatus = getCommonStart(action.payload) },
        paymentStatusSuccess: (state, action) => { state.paymentStatus = getCommonSuccess(action.payload) },
        paymentStatusFailure: (state, action) => { state.paymentStatus = getCommonFailure(action.payload) },

        // Bulk Action
        paymentBulkActionStart: (state) => { state.paymentBulkAction = getCommonStart() },
        paymentBulkActionSuccess: (state, action) => { state.paymentBulkAction = getCommonSuccess(action.payload) },
        paymentBulkActionFailure: (state, action) => { state.paymentBulkAction = getCommonFailure(action.payload) },

        // Delete payment
        paymentDeleteStart: (state, action) => { state.paymentDelete = getCommonStart(action.payload) },
        paymentDeleteSuccess: (state, action) => { state.paymentDelete = getCommonSuccess(action.payload) },
        paymentDeleteFailure: (state, action) => { state.paymentDelete = getCommonFailure(action.payload) },

        // View payment
        paymentViewStart: (state) => { state.paymentView = getCommonStart() },
        paymentViewSuccess: (state, action) => { state.paymentView = getCommonSuccess(action.payload) },
        paymentViewFailure: (state, action) => { state.paymentView = getCommonFailure(action.payload) },

        // Save payment
        paymentSaveStart: (state) => { state.paymentSave = getCommonStart() },
        paymentSaveSuccess: (state, action) => { state.paymentSave = getCommonSuccess(action.payload) },
        paymentSaveFailure: (state, action) => { state.paymentSave = getCommonFailure(action.payload) }, 

    }
});


export const {
    paymentListStart,
    paymentListSuccess,
    paymentListFailure,
    paymentStatusStart,
    paymentStatusSuccess,
    paymentStatusFailure,
    paymentBulkActionStart,
    paymentBulkActionSuccess,
    paymentBulkActionFailure,
    paymentDeleteStart,
    paymentDeleteSuccess,
    paymentDeleteFailure,
    paymentViewStart,
    paymentViewSuccess,
    paymentViewFailure,
    paymentSaveStart,
    paymentSaveSuccess,
    paymentSaveFailure,
    billingAccountsStart,
    billingAccountsSuccess,
    billingAccountsFailure,
} = PaymentSlice.actions;

export default PaymentSlice.reducer;