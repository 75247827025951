import { createSlice } from "@reduxjs/toolkit";
import {
    getCommonInitialState,
    getCommonStart,
    getCommonSuccess,
    getCommonFailure,
} from "../utils";

const initialState = {
    theme: "",
    login: getCommonInitialState(),
    register: getCommonInitialState(),
    forgotPassword: getCommonInitialState(),
    resetPassword: getCommonInitialState(),
    profile: getCommonInitialState(),
    updateProfile: getCommonInitialState(),
    dashboard: getCommonInitialState(),
    generateAPIKey: getCommonInitialState(),
    viewAPIKey: getCommonInitialState(),
    kycApplicant: getCommonInitialState(),
    kycStatusUpdate: getCommonInitialState(),
    changePassword: getCommonInitialState(),
};

const AdminSlice = createSlice({
    name: "admin",
    initialState,
    reducers: {

        //set theme
        setTheme: (state, action) => { state.theme = action.payload },

        //Login
        loginStart: (state, action) => { state.login = getCommonStart() },
        loginSuccess: (state, action) => { state.login = getCommonSuccess(action.payload) },
        loginFailure: (state, action) => { state.login = getCommonFailure(action.payload) },

        //Register
        registerStart: (state, action) => { state.register = getCommonStart() },
        registerSuccess: (state, action) => { state.register = getCommonSuccess(action.payload) },
        registerFailure: (state, action) => { state.register = getCommonFailure(action.payload) },

        //Forgot password
        forgotPasswordStart: (state, action) => { state.forgotPassword = getCommonStart() },
        forgotPasswordSuccess: (state, action) => { state.forgotPassword = getCommonSuccess(action.payload) },
        forgotPasswordFailure: (state, action) => { state.forgotPassword = getCommonFailure(action.payload) },

        //Reset Password
        resetPasswordStart: (state, action) => { state.resetPassword = getCommonStart() },
        resetPasswordSuccess: (state, action) => { state.resetPassword = getCommonSuccess(action.payload) },
        resetPasswordFailure: (state, action) => { state.resetPassword = getCommonFailure(action.payload) },

        //Profile
        profileStart: (state, action) => { state.profile = getCommonStart() },
        profileSuccess: (state, action) => { state.profile = getCommonSuccess(action.payload) },
        profileFailure: (state, action) => { state.profile = getCommonFailure(action.payload) },

        //Update Profile
        updateProfileStart: (state, action) => { state.updateProfile = getCommonStart() },
        updateProfileSuccess: (state, action) => { state.updateProfile = getCommonSuccess(action.payload) },
        updateProfileFailure: (state, action) => { state.updateProfile = getCommonFailure(action.payload) },

        //Dashboard
        dashboardStart: (state, action) => { state.dashboard = getCommonStart() },
        dashboardSuccess: (state, action) => { state.dashboard = getCommonSuccess(action.payload) },
        dashboardFailure: (state, action) => { state.dashboard = getCommonFailure(action.payload) },

        //Generate API key for user
        generateAPIKeyStart: (state, action) => { state.generateAPIKey = getCommonStart() },
        generateAPIKeySuccess: (state, action) => { state.generateAPIKey = getCommonSuccess(action.payload) },
        generateAPIKeyFailure: (state, action) => { state.generateAPIKey = getCommonFailure(action.payload) },

        //View API Key 
        viewAPIKeyStart: (state, action) => { state.viewAPIKey = getCommonStart() },
        viewAPIKeySuccess: (state, action) => { state.viewAPIKey = getCommonSuccess(action.payload) },
        viewAPIKeyFailure: (state, action) => { state.viewAPIKey = getCommonFailure(action.payload) },

        //Change Password
        changePasswordStart: (state, action) => { state.changePassword = getCommonStart() },
        changePasswordSuccess: (state, action) => { state.changePassword = getCommonSuccess(action.payload) },
        changePasswordFailure: (state, action) => { state.changePassword = getCommonFailure(action.payload) },

        // Create kyc applicant
        kycApplicantStart: (state, action) => { state.kycApplicant = getCommonStart() },
        kycApplicantSuccess: (state, action) => { state.kycApplicant = getCommonSuccess(action.payload) },
        kycApplicantFailure: (state, action) => { state.kycApplicant = getCommonFailure(action.payload) },

        // Update kyc status
        kycStatusUpdateStart: (state, action) => { state.kycStatusUpdate = getCommonStart() },
        kycStatusUpdateSuccess: (state, action) => { state.kycStatusUpdate = getCommonSuccess(action.payload) },
        kycStatusUpdateFailure: (state, action) => { state.kycStatusUpdate = getCommonFailure(action.payload) },

    }
});


export const {
    setTheme,
    loginStart,
    loginSuccess,
    loginFailure,
    registerStart,
    registerSuccess,
    registerFailure,
    forgotPasswordStart,
    forgotPasswordSuccess,
    forgotPasswordFailure,
    resetPasswordStart,
    resetPasswordSuccess,
    resetPasswordFailure,
    profileStart,
    profileSuccess,
    profileFailure,
    updateProfileStart,
    updateProfileSuccess,
    updateProfileFailure,
    dashboardStart,
    dashboardSuccess,
    dashboardFailure,
    generateAPIKeyStart,
    generateAPIKeySuccess,
    generateAPIKeyFailure,
    viewAPIKeyStart,
    viewAPIKeySuccess,
    viewAPIKeyFailure,
    kycApplicantStart,
    kycApplicantSuccess,
    kycApplicantFailure,
    kycStatusUpdateStart,
    kycStatusUpdateSuccess,
    kycStatusUpdateFailure,
    changePasswordStart,
    changePasswordSuccess,
    changePasswordFailure,

} = AdminSlice.actions;

export default AdminSlice.reducer;