import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  dashboardStart,
  profileStart,
  kycApplicantStart,
  kycStatusUpdateStart,
} from "../../store/slices/AdminSlice";
import PageLoader from "../Helper/PageLoader";
import SomethingWentWrong from "../Helper/SomethingWentWrong";
import MapChart from "./MapChart";
import TicketsChart from "./TicketsChart";
import SumsubWebSdk from "@sumsub/websdk-react";

const DashboardIndex = () => {
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.admin.profile);
  const dashboard = useSelector((state) => state.admin.dashboard);
  const kycApplicant = useSelector((state) => state.admin.kycApplicant);

  useEffect(() => {
    if (!profile.loading && Object.keys(profile.data).length > 0) {
      dispatch(dashboardStart());
      if (profile.data.kyc_status != 3) {
        dispatch(kycApplicantStart());
      }
    }
  }, [profile]);

  const options = {};
  const messageHandler = (type, payload) => {
    if (type == "idCheck.applicantReviewComplete" && payload.authCode) {
      dispatch(
        kycStatusUpdateStart({
          kyc_status: 3,
          kyc_auth_code: payload.authCode,
        })
      );
    }
  };
  const errorHandler = (data) => console.log("onError", data);
  const accessTokenExpirationHandler = () => {};
  return profile.loading ? (
    <PageLoader />
  ) : Object.keys(profile.data).length > 0 ? (
    <>
      <div className="flex items-center space-x-4 py-5 lg:py-6">
        <h2 className="text-xl font-medium text-slate-800 dark:text-navy-50 lg:text-2xl">
          {" "}
          Dashboard{" "}
        </h2>
      </div>
      {profile.data.kyc_status == 3 ? (
        <div className="grid w-full grow grid-cols-1 place-items-center">
          <div className="max-w-md p-6 text-center">
            <div className="w-full">
              <img
                className="w-full"
                src={
                  window.location.origin + "/images/illustrations/error-401.svg"
                }
                alt="image"
              />
            </div>
            <p className="pt-4 text-7xl font-bold text-primary dark:text-accent">
              KYC Verified
            </p>
            {/* <p className="pt-4 text-xl font-semibold text-slate-800 dark:text-navy-50">
              KYC Verified
            </p> */}
          </div>
        </div>
      ) : kycApplicant.loading ? (
        <PageLoader />
      ) : Object.keys(kycApplicant.data).length > 0 ? (
        <SumsubWebSdk
          accessToken={kycApplicant.data.token}
          expirationHandler={accessTokenExpirationHandler}
          config={{}}
          options={options}
          onMessage={messageHandler}
          onError={errorHandler}
        />
      ) : (
        <SomethingWentWrong
          buttonText="Retry"
          handleClick={() => dispatch(profileStart())}
        />
      )}
      {/* {dashboard.loading ? (
        <PageLoader />
      ) : Object.keys(dashboard.data).length > 0 ? (
        <>
          <div className="grid grid-cols-1 gap-4 transition-all duration-[.25s] sm:mt-5 sm:grid-cols-1 sm:gap-5 lg:mt-6 lg:grid-cols-3 lg:gap-6">
            <div className="card p-4 sm:col-span-2 sm:p-5">
              <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 sm:gap-5 lg:gap-6">
                <a href="https://admin.haive.tech/admin/users">
                  <div className="flex items-center space-x-4 rounded-2xl border border-slate-150 p-4 dark:border-navy-600">
                    <div className="mask is-star-2 flex h-12 w-12 items-center justify-center bg-secondary/10 dark:bg-secondary">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        className="w-6 text-secondary h-6"
                      >
                        <path
                          fillRule="evenodd"
                          d="M7.5 6a4.5 4.5 0 119 0 4.5 4.5 0 01-9 0zM3.751 20.105a8.25 8.25 0 0116.498 0 .75.75 0 01-.437.695A18.683 18.683 0 0112 22.5c-2.786 0-5.433-.608-7.812-1.7a.75.75 0 01-.437-.695z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                    </div>
                    <div className="font-inter">
                      <p className="text-base font-semibold text-slate-700 dark:text-navy-100">
                        {dashboard.data.data.total_users}
                      </p>
                      <p>Users</p>
                    </div>
                  </div>
                </a>
                <a href="https://admin.haive.tech/admin/tickets">
                  <div className="flex items-center space-x-4 rounded-2xl border border-slate-150 p-4 dark:border-navy-600">
                    <div className="mask is-star-2 flex h-12 w-12 items-center justify-center bg-info/10 dark:bg-info">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        className="w-6 text-info h-6"
                      >
                        <path
                          fillRule="evenodd"
                          d="M1.5 6.375c0-1.036.84-1.875 1.875-1.875h17.25c1.035 0 1.875.84 1.875 1.875v3.026a.75.75 0 01-.375.65 2.249 2.249 0 000 3.898.75.75 0 01.375.65v3.026c0 1.035-.84 1.875-1.875 1.875H3.375A1.875 1.875 0 011.5 17.625v-3.026a.75.75 0 01.374-.65 2.249 2.249 0 000-3.898.75.75 0 01-.374-.65V6.375zm15-1.125a.75.75 0 01.75.75v.75a.75.75 0 01-1.5 0V6a.75.75 0 01.75-.75zm.75 4.5a.75.75 0 00-1.5 0v.75a.75.75 0 001.5 0v-.75zm-.75 3a.75.75 0 01.75.75v.75a.75.75 0 01-1.5 0v-.75a.75.75 0 01.75-.75zm.75 4.5a.75.75 0 00-1.5 0V18a.75.75 0 001.5 0v-.75zM6 12a.75.75 0 01.75-.75H12a.75.75 0 010 1.5H6.75A.75.75 0 016 12zm.75 2.25a.75.75 0 000 1.5h3a.75.75 0 000-1.5h-3z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                    </div>
                    <div className="font-inter">
                      <p className="text-base font-semibold text-slate-700 dark:text-navy-100">
                        {dashboard.data.data.total_tickets}
                      </p>
                      <p>Tickets</p>
                    </div>
                  </div>
                </a>
                <a href="https://admin.haive.tech/admin/clients">
                  <div className="flex items-center space-x-4 rounded-2xl border border-slate-150 p-4 dark:border-navy-600">
                    <div className="mask is-star-2 flex h-12 w-12 items-center justify-center bg-primary/10 dark:bg-primary">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        className="w-6 text-primary h-6"
                      >
                        <path d="M6.25 6.375a4.125 4.125 0 118.25 0 4.125 4.125 0 01-8.25 0zM3.25 19.125a7.125 7.125 0 0114.25 0v.003l-.001.119a.75.75 0 01-.363.63 13.067 13.067 0 01-6.761 1.873c-2.472 0-4.786-.684-6.76-1.873a.75.75 0 01-.364-.63l-.001-.122zM19.75 7.5a.75.75 0 00-1.5 0v2.25H16a.75.75 0 000 1.5h2.25v2.25a.75.75 0 001.5 0v-2.25H22a.75.75 0 000-1.5h-2.25V7.5z"></path>
                      </svg>
                    </div>
                    <div className="font-inter">
                      <p className="text-base font-semibold text-slate-700 dark:text-navy-100">
                        {dashboard.data.data.total_clients}
                      </p>
                      <p>Clients</p>
                    </div>
                  </div>
                </a>
                <a href="https://admin.haive.tech/admin/support_agents">
                  <div className="flex items-center space-x-4 rounded-2xl border border-slate-150 p-4 dark:border-navy-600">
                    <div className="mask is-star-2 flex h-12 w-12 items-center justify-center bg-error/10 dark:bg-error">
                      <i
                        className="fa fa-user-secret fa-lg text-warning"
                        aria-hidden="true"
                      ></i>
                    </div>
                    <div className="font-inter">
                      <p className="text-base font-semibold text-slate-700 dark:text-navy-100">
                        {dashboard.data.data.total_support_agents}
                      </p>
                      <p>Support Agents</p>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div className="card">
              <div className="mt-3.5 flex grow items-baseline justify-between px-4 sm:px-5">
                <div>
                  <p className="font-medium">Tickets</p>
                  <p className="text-2xl font-semibold text-slate-700 dark:text-navy-100">
                    823
                  </p>
                </div>
                <div className="badge space-x-1 rounded-full bg-success/10 py-1 px-1.5 text-success dark:bg-success/15">
                  <span className="text-success"> Last 10 days </span>
                </div>
              </div>
              <div className="ax-transparent-gridline">
                <TicketsChart tickets={dashboard.data.data.ticket} />
              </div>
            </div>
          </div>

          <div className="grid grid-cols-1 gap-4 sm:grid-cols-1 sm:gap-5 lg:gap-6 mt-6">
            <div>
              <div className="space-y-4 sm:space-y-5 lg:space-y-6">
                <div className="card px-4 pb-4 sm:px-5">
                  <div className="my-5 flex h-8 items-center justify-between">
                    <div>
                      <h2 className="font-medium tracking-wide text-slate-700 line-clamp-1 dark:text-navy-100 lg:text-base">
                        Users
                      </h2>
                    </div>
                    <div>
                      <a
                        href="https://admin.haive.tech/admin/users"
                        className="btn border border-secondary/30 bg-secondary/10 font-medium text-secondary hover:bg-secondary/20 focus:bg-secondary/20 active:bg-secondary/25 dark:border-secondary-light/30 dark:bg-secondary-light/10 dark:text-secondary-light dark:hover:bg-secondary-light/20 dark:focus:bg-secondary-light/20 dark:active:bg-secondary-light/25"
                      >
                        View All
                      </a>
                    </div>
                  </div>
                  <h1 className="text-sm font-semibold">
                    Last 10 days users analytics
                  </h1>
                  <div className="">
                    <div className="mt-5">
                      <div>
                        <MapChart chartID="map" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <SomethingWentWrong
          buttonText="Retry"
          handleClick={() => dispatch(dashboardStart())}
        />
      )} */}
    </>
  ) : (
    <SomethingWentWrong
      buttonText="Retry"
      handleClick={() => dispatch(profileStart())}
    />
  );
};

export default DashboardIndex;
