import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const SomethingWentWrong = ({ buttonText = "", handleClick = () => { } }) => {
  const theme = useSelector(state => state.admin.theme);

  return (
    <div className="grid w-full grow grid-cols-1 place-items-center">
      <div className="max-w-md p-6 text-center">
        <div className="w-full">
          <img className="w-full" src={window.location.origin + "/images/illustrations/error-500.svg"} alt="image" />
        </div>
        <p className="pt-4 text-7xl font-bold text-primary dark:text-accent">
          Oops!
        </p>
        <p className="pt-4 text-xl font-semibold text-slate-800 dark:text-navy-50">
          Something Went Wrong
        </p>
        {/* <p className="pt-2 text-slate-500 dark:text-navy-200">
        The server has been deserted for a while. Please be patient or try
        again later
      </p> */}
        {buttonText ?
          <button
            className="btn mt-10 h-11 bg-primary text-base font-medium text-white hover:bg-primary-focus hover:shadow-lg hover:shadow-primary/50 focus:bg-primary-focus focus:shadow-lg focus:shadow-primary/50 active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:hover:shadow-accent/50 dark:focus:bg-accent-focus dark:focus:shadow-accent/50 dark:active:bg-accent/90"
            onClick={() => handleClick()}
          >
            {buttonText}
          </button>
          : null
        }
      </div>
    </div>
  );
}

export default SomethingWentWrong;