import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import { paymentViewStart } from "../../store/slices/PaymentSlice";
import { ButtonLoader } from "../Helper/Loader";
import PageLoader from "../Helper/PageLoader";
import SomethingWentWrong from "../Helper/SomethingWentWrong";
import { confirmAlert } from "react-confirm-alert";
import { profileStart } from "../../store/slices/AdminSlice";

const PaymentView = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const profile = useSelector((state) => state.admin.profile);
  const paymentView = useSelector((state) => state.payment.paymentView);
  const [skipRender, setSkipRender] = useState(true);

  useEffect(() => {
    if (!profile.loading && Object.keys(profile.data).length > 0)
      dispatch(paymentViewStart({ payment_id: params.paymentId }));
  }, [profile]);

  const getStatusClass = (status) => {
    switch (status) {
      case 1:
        return "badge space-x-2.5 rounded-full bg-primary/10 text-primary dark:bg-primary/15";
      case 2:
        return "badge space-x-2.5 rounded-full bg-success/10 text-success dark:bg-success/15";
      case 3:
        return "badge space-x-2.5 rounded-full bg-error/10 text-error dark:bg-error/15";
      default:
        return "badge space-x-2.5 rounded-full bg-primary/10 text-primary dark:bg-primary/15";
    }
  };

  return profile.loading ? (
    <PageLoader />
  ) : Object.keys(profile.data).length > 0 ? (
    <>
      <div className="flex items-center space-x-4 py-5 lg:py-6">
        <h2 className="text-xl font-medium text-slate-800 dark:text-navy-50 lg:text-2xl">
          {" "}
          Payments{" "}
        </h2>
        <div className="hidden h-full py-1 sm:flex">
          <div className="h-full w-px bg-slate-300 dark:bg-navy-600"></div>
        </div>
        <ul className="hidden flex-wrap items-center space-x-2 sm:flex">
          <li className="flex items-center space-x-2">
            <Link
              to="/payment"
              className="text-primary transition-colors hover:text-primary-focus dark:text-accent-light dark:hover:text-accent"
            >
              Payments
            </Link>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-4 w-4"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M9 5l7 7-7 7"
              ></path>
            </svg>
          </li>
          <li>View Payment</li>
        </ul>
      </div>
      <div className="flex items-center justify-start gap-4 pb-2">
        <Link
          to="/payment/create"
          className="btn space-x-2 bg-primary font-medium text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            className="w-6 h-6"
          >
            <path
              fillRule="evenodd"
              d="M3 6a3 3 0 013-3h2.25a3 3 0 013 3v2.25a3 3 0 01-3 3H6a3 3 0 01-3-3V6zm9.75 0a3 3 0 013-3H18a3 3 0 013 3v2.25a3 3 0 01-3 3h-2.25a3 3 0 01-3-3V6zM3 15.75a3 3 0 013-3h2.25a3 3 0 013 3V18a3 3 0 01-3 3H6a3 3 0 01-3-3v-2.25zm9.75 0a3 3 0 013-3H18a3 3 0 013 3V18a3 3 0 01-3 3h-2.25a3 3 0 01-3-3v-2.25z"
              clipRule="evenodd"
            ></path>
          </svg>
          <span>Add Payment</span>
        </Link>
        <Link
          to="/payment"
          className="btn space-x-2 bg-success font-medium text-white hover:bg-success-focus focus:bg-success-focus active:bg-success-focus/90"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            className="w-6 h-6"
          >
            <path
              fillRule="evenodd"
              d="M3 6a3 3 0 013-3h2.25a3 3 0 013 3v2.25a3 3 0 01-3 3H6a3 3 0 01-3-3V6zm9.75 0a3 3 0 013-3H18a3 3 0 013 3v2.25a3 3 0 01-3 3h-2.25a3 3 0 01-3-3V6zM3 15.75a3 3 0 013-3h2.25a3 3 0 013 3V18a3 3 0 01-3 3H6a3 3 0 01-3-3v-2.25zm9.75 0a3 3 0 013-3H18a3 3 0 013 3V18a3 3 0 01-3 3h-2.25a3 3 0 01-3-3v-2.25z"
              clipRule="evenodd"
            ></path>
          </svg>
          <span>View Payments</span>
        </Link>
      </div>
      {paymentView.loading ? (
        <PageLoader />
      ) : Object.keys(paymentView.data).length > 0 ? (
        <div className="card px-4 pb-4 sm:px-5">
          <div className="grid grid-cols-2 gap-4 sm:gap-5 lg:gap-6">
            <div>
              <div className="mt-2">
                <div className="is-scrollbar-hidden min-w-full overflow-x-auto rounded-lg border border-slate-200 dark:border-navy-500">
                  <table className="w-full text-left">
                    <thead></thead>
                    <tbody>
                      <tr>
                        <td className="whitespace-nowrap text-sm font-semibold border border-slate-200 px-3 py-3 dark:border-navy-500 lg:px-5">
                          Transaction ID
                        </td>
                        <td className="whitespace-nowrap border border-slate-200 px-3 py-3 dark:border-navy-500 lg:px-5">
                          {paymentView.data.user_payment.transaction_id}
                        </td>
                      </tr>
                      <tr>
                        <td className="whitespace-nowrap text-sm font-semibold border border-slate-200 px-3 py-3 dark:border-navy-500 lg:px-5">
                          Amount
                        </td>
                        <td className="whitespace-nowrap border border-slate-200 px-3 py-3 dark:border-navy-500 lg:px-5">
                          {paymentView.data.user_payment.amount}
                        </td>
                      </tr>
                      <tr>
                        <td className="whitespace-nowrap text-sm font-semibold border border-slate-200 px-3 py-3 dark:border-navy-500 lg:px-5">
                          Status
                        </td>
                        <td className="whitespace-nowrap border border-slate-200 dark:border-navy-500 lg:px-5">
                          <div className="avatar">
                            <label className="inline-flex items-center space-x-2">
                              <div
                                className={getStatusClass(
                                  paymentView.data.user_payment.status
                                )}
                                id="statusText156"
                              >
                                <div className="h-2 w-2 rounded-full bg-current"></div>
                                <span id="statusText156">
                                  {
                                    paymentView.data.user_payment
                                      .status_formatted
                                  }
                                </span>
                              </div>
                            </label>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div>
              <div className="mt-2">
                <div className="is-scrollbar-hidden min-w-full overflow-x-auto rounded-lg border border-slate-200 dark:border-navy-500">
                  <table className="w-full text-left">
                    <thead></thead>
                    <tbody>
                      <tr>
                        <td className="whitespace-nowrap text-sm font-semibold border border-slate-200 px-3 py-3 dark:border-navy-500 lg:px-5">
                          Billing Account Number
                        </td>
                        <td className="whitespace-nowrap border border-slate-200 px-3 py-3 dark:border-navy-500 lg:px-5">
                          {
                            paymentView.data.user_payment.billing_account
                              .account_number
                          }
                        </td>
                      </tr>
                      <tr>
                        <td className="whitespace-nowrap text-sm font-semibold border border-slate-200 px-3 py-3 dark:border-navy-500 lg:px-5">
                          Created at
                        </td>
                        <td className="whitespace-nowrap border border-slate-200 px-3 py-3 dark:border-navy-500 lg:px-5">
                          {paymentView.data.user_payment.created_at}
                        </td>
                      </tr>
                      <tr>
                        <td className="whitespace-nowrap text-sm font-semibold border border-slate-200 px-3 py-3 dark:border-navy-500 lg:px-5">
                          Updated at
                        </td>
                        <td className="whitespace-nowrap border border-slate-200 px-3 py-3 dark:border-navy-500 lg:px-5">
                          {paymentView.data.user_payment.updated_at}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="is-scrollbar-hidden min-w-full overflow-x-auto rounded-lg border border-slate-200 dark:border-navy-500 my-5 p-5">
            <div className="grid grid-cols-2 gap-4 sm:gap-5 lg:gap-6">
              {paymentView.data.user_payment.status == 3 && (
                <div>
                  <h2 class="text-2xl pb-2">Cancelled Reason</h2>
                  <p>{paymentView.data.user_payment.cancel_reason}</p>
                </div>
              )}
              <div>
                <h2 class="text-2xl pb-2">Payment Screenshot</h2>
                <img
                  className="h-48 p-3 w-full shrink-0 rounded-t-lg bg-cover bg-center object-cover object-center lg:h-auto lg:w-48 lg:rounded-t-none lg:rounded-l-lg"
                  src={paymentView.data.user_payment.picture}
                  alt="image"
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <SomethingWentWrong
          buttonText="Retry"
          handleClick={() =>
            dispatch(paymentViewStart({ payment_id: params.paymentId }))
          }
        />
      )}
    </>
  ) : (
    <SomethingWentWrong
      buttonText="Retry"
      handleClick={() => dispatch(profileStart())}
    />
  );
};

export default PaymentView;
