import React, { useState, useRef } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { changePasswordStart } from '../../store/slices/AdminSlice';
import { ButtonLoader } from '../Helper/Loader';
import { useEffect } from 'react';
import useLogout from '../../hooks/useLogout';


const ChangePasswordTab = ({ resetTab }) => {
  const { logout } = useLogout();
  const dispatch = useDispatch();
  const formRef = useRef();
  const changePassword = useSelector(state => state.admin.changePassword);
  const [skipRender, setSkipRender] = useState(true);

  const validationSchema = Yup.object().shape({
    old_password: Yup.string().matches(/^(?=.*[a-zA-Z0-9])(?=.{6,})/, "Invalid password").required("Required"),
    password: Yup.string().matches(/^(?=.*[a-zA-Z0-9])(?=.{6,})/, "Invalid password")
      .required("Required")
      .when("old_password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().notOneOf(
          [Yup.ref("old_password")],
          "New password should not be same as old password"
        ),
      }),
    password_confirmation: Yup.string().matches(/^(?=.*[a-zA-Z0-9])(?=.{6,})/, "Invalid password")
      .required("Required")
      .when("password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          "comfirm password should be same as new pasword"
        ),
      }),
  });

  const handleSubmit = values => dispatch(changePasswordStart(values));

  const onCancel = () => {
    if (formRef.current) formRef.current.resetForm();
    resetTab();
  }

  useEffect(() => {
    if (!skipRender && !changePassword.loading && Object.keys(changePassword.data).length > 0) {
      logout();
    }
    setSkipRender(false);
  }, [changePassword]);

  return (
    <div>
      <div className="grid grid-cols-12 gap-4 sm:gap-5 lg:gap-6">
        <div className="col-span-12 lg:col-span-12">
          <Formik
            initialValues={{
              old_password: "",
              password: "",
              password_confirmation: "",
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            innerRef={formRef}
          >
            <Form className="form">
              <div className="">
                <div className="flex flex-col">
                  <div className="p-4 sm:p-5">
                    <div className="space-y-5">
                      <label className="block">
                        <span className="font-medium text-slate-600 dark:text-navy-100">Old Password *</span>
                        <Field
                          className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                          placeholder="Enter Old Password"
                          name="old_password"
                          type="password"
                        />
                      </label>
                      <span className="text-tiny+ text-slate-400 dark:text-navy-300">
                        <ErrorMessage
                          component={"div"}
                          name="old_password"
                          className="errorMsg w-100"
                        />
                      </span>
                      <div className="flex flex-row gap-6">
                        <div className="w-1/2">
                          <label className="block">
                            <span className="font-medium text-slate-600 dark:text-navy-100">New Password *</span>
                            <Field
                              className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                              placeholder="Enter New Password"
                              name="password"
                              type="password"
                            />
                          </label>
                          <div className="flex justify-between">
                            <span className="text-tiny+ text-slate-400 dark:text-navy-300">
                              Max characters : 6
                            </span>
                            <ErrorMessage
                              component={"div"}
                              name="password"
                              className="errorMsg w-100 text-tiny+"
                            />
                          </div>
                        </div>
                        <div className="w-1/2">
                          <label className="block">
                            <span className="font-medium text-slate-600 dark:text-navy-100">Confirm Password *</span>
                            <Field
                              className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                              placeholder="Enter Confirm Password"
                              name="password_confirmation"
                              type="password"
                            />
                          </label>
                          <span className="text-tiny+ text-slate-400 dark:text-navy-300">
                            <ErrorMessage
                              component={"div"}
                              name="password_confirmation"
                              className="errorMsg w-100"
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex justify-end space-x-2">
                <button
                  type="button"
                  className="btn min-w-[7rem] rounded-full border font-medium text-slate-700 hover:bg-slate-150 focus:bg-slate-150 active:bg-slate-150/80 dark:border-navy-450 dark:text-navy-100 dark:hover:bg-navy-500 dark:focus:bg-navy-500 dark:active:bg-navy-500/90"
                  onClick={() => onCancel()}>
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn min-w-[7rem] rounded-full bg-primary font-medium text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90"
                  disabled={changePassword.buttonDisable}
                >
                  {changePassword.buttonDisable ? <ButtonLoader varient="info" size={5} /> : "Submit"}
                </button>
              </div>
            </Form>
          </Formik>
        </div>
      </div>
    </div>
  );
}

export default ChangePasswordTab;