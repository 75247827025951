import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import PageLoader from '../Helper/PageLoader';
import SomethingWentWrong from '../Helper/SomethingWentWrong';
import { profileStart } from '../../store/slices/AdminSlice';
import { paymentListStart } from '../../store/slices/PaymentSlice';
import Pagination from '../Helper/Pagination';
import { Formik, Field, Form } from 'formik';

const PaymentIndex = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const profile = useSelector(state => state.admin.profile);
  const paymentList = useSelector(state => state.payment.paymentList);
  const [skipRender, setSkipRender] = useState(true);
  const [filterStatus, setFilterStatus] = useState(false);
  const [page, setPage] = useState(searchParams.get("page") ? searchParams.get("page") : 1);
  const [filter, setFilter] = useState({
    search_key: searchParams.get("search_key") ? searchParams.get("search_key") : "",
    status: searchParams.get("status") ? searchParams.get("status") : "",
  });

  useEffect(() => {
    if (!profile.loading && Object.keys(profile.data).length > 0) {
      dispatch(paymentListStart({
        ...filter,
        skip: 12 * (page - 1),
        take: 12,
      }));
    }
  }, [profile, page, filter]);

  useEffect(() => {
    if (!skipRender) {
      setPage(searchParams.get("page") ? searchParams.get("page") : 1);
      setFilter({
        search_key: searchParams.get("search_key") ? searchParams.get("search_key") : "",
        status: searchParams.get("status") ? searchParams.get("status") : "",
      })
    }
    setSkipRender(false);
  }, [searchParams.get("page"), searchParams.get("search_key"), searchParams.get("status")]);

  const handlePageClick = ({ selected }) => {
    let params = searchParams.get("search_key") ? `search_key=${searchParams.get("search_key")}&` : "";
    params += searchParams.get("status") ? `status=${searchParams.get("status")}&` : "";
    navigate(`/payment?${params}page=${selected + 1}`);
  }

  const getStatusClass = (status) => {
    switch (status) {
      case 1:
        return "badge space-x-2.5 rounded-full bg-primary/10 text-primary dark:bg-primary/15";
      case 2:
        return "badge space-x-2.5 rounded-full bg-success/10 text-success dark:bg-success/15";
      case 3:
        return "badge space-x-2.5 rounded-full bg-error/10 text-error dark:bg-error/15";
      default:
        return "badge space-x-2.5 rounded-full bg-primary/10 text-primary dark:bg-primary/15";
    }
  };

  return (
    profile.loading ?
      <PageLoader />
      : Object.keys(profile.data).length > 0 ?
        <>
          {/* HEADER */}
          <div className="flex items-center justify-between py-5 lg:py-6">
            <div className="flex items-center space-x-4">
              <h2 className="text-xl font-medium text-slate-800 dark:text-navy-50 lg:text-2xl"> Payments </h2>
              <div className="hidden h-full py-1 sm:flex">
                <div className="h-full w-px bg-slate-300 dark:bg-navy-600">&nbsp;</div>
              </div>
              <ul className="hidden flex-wrap items-center space-x-2 sm:flex">
                <li className="flex items-center space-x-2">
                  <Link
                    to="/payment"
                    className="text-primary transition-colors hover:text-primary-focus dark:text-accent-light dark:hover:text-accent">
                    Payments
                  </Link>
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7"></path>
                  </svg>
                </li>
                <li>View Payments </li>
              </ul>
            </div>
          </div>
          {/* FILTER */}
          <div className="grid grid-cols-1 gap-4 sm:gap-5 lg:gap-6">
            <div className="flex items-center justify-between">
              <Link
                to="/payment/create"
                className="btn space-x-2 bg-primary font-medium text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90"
              >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                  <path fillRule="evenodd" d="M3 6a3 3 0 013-3h2.25a3 3 0 013 3v2.25a3 3 0 01-3 3H6a3 3 0 01-3-3V6zm9.75 0a3 3 0 013-3H18a3 3 0 013 3v2.25a3 3 0 01-3 3h-2.25a3 3 0 01-3-3V6zM3 15.75a3 3 0 013-3h2.25a3 3 0 013 3V18a3 3 0 01-3 3H6a3 3 0 01-3-3v-2.25zm9.75 0a3 3 0 013-3H18a3 3 0 013 3V18a3 3 0 01-3 3h-2.25a3 3 0 01-3-3v-2.25z" clipRule="evenodd"></path>
                </svg>
                <span>Add payment</span>
              </Link>
              <Formik
                initialValues={{
                  search_key: searchParams.get("search_key") ? searchParams.get("search_key") : "",
                  status: searchParams.get("status") ? searchParams.get("status") : "",
                }}
                onSubmit={val => {
                  navigate(`/payment?search_key=${val.search_key}&status=${val.status}`)
                }}
              >
                {({ values, setFieldValue, resetForm }) =>
                  <Form className="form">
                    <div className="flex items-center gap-2 justify-between">
                      {/* <div>
                        <label className="block">
                          <Field
                            className="form-input w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                            placeholder="Search by Name"
                            type="text"
                            name="search_key"
                          />
                        </label>
                      </div> */}
                      <div onClick={() => setFilterStatus(status => !status)} onBlur={() => setTimeout(() => { setFilterStatus(false) }, 200)}>
                        <div className="ts-wrapper w-16em single">
                          <div className="ts-control">
                            <input
                              type="select-one"
                              placeholder="Select Status"
                              readOnly={true}
                              value={values.status === "1" ? "Initiated" : values.status === "2" ? "Approved" : values.status === "3" ? "Declined" : ""}
                            />
                          </div>
                          <div className="ts-dropdown single" style={{ display: filterStatus ? "block" : "none", visibility: "visible" }}>
                            <div role="listbox" tabIndex="-1" className="ts-dropdown-content" id="tomOne-ts-dropdown">
                              <div data-selectable="" className={`option ${values.status === "" ? "active" : ""}`} onClick={() => setFieldValue("status", "")}> All </div>
                              <div data-selectable="" className={`option ${values.status === "1" ? "active" : ""}`} onClick={() => setFieldValue("status", "1")}> Initiated </div>
                              <div data-selectable="" className={`option ${values.status === "2" ? "active" : ""}`} onClick={() => setFieldValue("status", "2")}> Approved </div>
                              <div data-selectable="" className={`option ${values.status === "3" ? "active" : ""}`} onClick={() => setFieldValue("status", "3")}> Declined </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <button type="submit"
                          className="btn mr-1 h-9 w-9 rounded-full bg-success p-0 font-medium text-white hover:bg-success-focus hover:shadow-lg hover:shadow-success/50 focus:bg-success-focus active:bg-success-focus/90"
                          data-tooltip-id="tooltip-success"
                          data-tooltip-content="Search"
                          data-tooltip-place="top"
                        >
                          <i className="fa-solid fa-search"></i>
                        </button>
                        <button
                          type="button"
                          className="btn h-9 w-9 rounded-full bg-error p-0 font-medium text-white hover:bg-error-focus hover:shadow-lg hover:shadow-error/50 focus:bg-error-focus active:bg-error-focus/90"
                          onClick={() => {
                            setFieldValue("search_key", "");
                            setFieldValue("status", "");
                            navigate('/payment');
                          }}
                          data-tooltip-id="tooltip-error"
                          data-tooltip-content="Clear"
                          data-tooltip-place="top"
                        >
                          <i className="fa-solid fa-remove"></i>
                        </button>
                      </div>
                    </div>
                  </Form>
                }
              </Formik>
            </div >
          </div >
          {/* TABLE */}
          {
            paymentList.loading ?
              <PageLoader />
              : Object.keys(paymentList.data).length > 0 ?
                <div className='card mt-3'>
                  <div className="is-scrollbar-hidden min-w-full overflow-x-auto">
                    <table className="is-hoverable w-full text-left">
                      <thead>
                        <tr>
                          <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5"> # </th>
                          <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                            Transaction Id
                          </th>
                          <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                            Account Number
                          </th>
                          <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                            Amount
                          </th>
                          <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                            Status
                          </th>
                          <th className="whitespace-nowrap rounded-r-lg bg-slate-200 px-3 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                            Actions
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {paymentList.data.user_payments.length > 0 ?
                          paymentList.data.user_payments.map((payment, i) =>
                            <tr className="border-y border-transparent border-b-slate-200 dark:border-b-navy-500" key={i}>
                              <td className="whitespace-nowrap px-4 py-3 sm:px-5"> {(12 * (page - 1)) + (i + 1)} </td>
                              <td className="whitespace-nowrap px-3 py-3 font-medium text-slate-700 dark:text-navy-100 lg:px-5">
                                {payment.transaction_id}
                              </td>
                              <td className="whitespace-nowrap px-3 py-3 font-medium text-slate-700 dark:text-navy-100 lg:px-5">
                                {payment.billing_account.account_number}
                              </td>
                              <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                                  {payment.amount}
                              </td>
                              <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                                <label className="inline-flex items-center space-x-2">
                                  {/* {payment.status == 1 ?
                                    <div className="badge space-x-2.5 rounded-full bg-success/10 text-success dark:bg-success/15" id="statusBadge156">
                                      <div className="h-2 w-2 rounded-full bg-current"></div>
                                      <span id="statusText156">Approved</span>
                                    </div>
                                    : <div className="badge space-x-2.5 rounded-full bg-error/10 text-error dark:bg-error/15" id="statusBadge19">
                                      <div className="h-2 w-2 rounded-full bg-current"></div>
                                      <span id="statusText19">Declined</span>
                                    </div>
                                  } */}
                                  <div className={getStatusClass(payment.status)} id="statusText156">
                                      <div className="h-2 w-2 rounded-full bg-current"></div>
                                      <span id="statusText156">{payment.status_formatted}</span>
                                    </div>
                                </label>
                              </td>
                              <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                                <label className="flex items-center space-x-2">
                                  <Link
                                    to={`/payment/show/${payment.id}`}
                                    className="btn h-9 w-9 rounded-full bg-secondary p-0 font-medium text-white hover:bg-secondary-focus hover:shadow-lg hover:shadow-secondary/50 focus:bg-secondary-focus active:bg-secondary-focus/90"
                                    data-tooltip-id="tooltip-secondary"
                                    data-tooltip-content="View"
                                    data-tooltip-place="top"
                                  >
                                    <i className="fa-solid fa-eye"></i>
                                  </Link>
                                </label>
                              </td>
                            </tr>
                          )
                          : <tr className="border-y border-transparent border-b-slate-200 dark:border-b-navy-500">
                            <td className="whitespace-nowrap px-3 py-3 font-medium text-slate-700 dark:text-navy-100 lg:px-5">
                              No Data Found
                            </td>
                          </tr>
                        }
                      </tbody>
                    </table>
                  </div>

                  <div className="flex flex-col justify-end space-y-4 px-4 py-4 sm:flex-row sm:items-center sm:space-y-0 sm:px-5">
                    {paymentList.data.user_payments.length > 0 ?
                      <ol className="pagination space-x-1.5">
                        <li>
                          <nav role="navigation" aria-label="Pagination Navigation" className="flex items-center justify-between">
                            <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
                              <div className='px-2'>
                                <p className="text-sm text-gray-700 leading-5">
                                  Showing
                                  <span className="font-medium"> {(12 * (page - 1)) + 1} </span>
                                  to
                                  <span className="font-medium"> {(12 * (page - 1)) + paymentList.data.user_payments.length} </span>
                                  of
                                  <span className="font-medium"> {paymentList.data.total_user_payments} </span>
                                  results
                                </p>
                              </div>
                              <Pagination
                                page={page}
                                pageCount={Math.ceil(paymentList.data.total_user_payments / 12)}
                                handlePageClick={handlePageClick}
                              />
                            </div>
                          </nav>
                        </li>
                      </ol>
                      : null
                    }
                  </div>
                </div>
                : <SomethingWentWrong buttonText="Retry" handleClick={() => { }} />
          }
        </>
        : <SomethingWentWrong buttonText="Retry" handleClick={() => dispatch(profileStart())} />
  );
}

export default PaymentIndex;