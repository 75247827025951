import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-multi-lang";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { registerStart } from "../../store/slices/AdminSlice";
import { ButtonLoader } from "../Helper/Loader";
import Helmet from "react-helmet";
import configuration from "react-global-configuration"
import PhoneInput, {
  formatPhoneNumberIntl,
  isValidPhoneNumber,
  isPossiblePhoneNumber,
} from "react-phone-number-input";
import axios from "axios";

const RegisterIndex = () => {
  const t = useTranslation("register");
  const dispatch = useDispatch();
  const formRef = useRef();
  const register = useSelector((state) => state.admin.register);
  const [defaultCountry, setDefaultCountry] = useState("");

  useEffect(() => {
    const getUserLocation = async () => {
      try {
        const response = await axios.get("http://ip-api.com/json/");
        setDefaultCountry(response.data.countryCode);
      } catch (error) {
        // If the API call fails, you can set a fallback country code here.
        setDefaultCountry("IN");
      }
    };
    getUserLocation();
  }, []);

  const registerSchema = Yup.object().shape({
    name: Yup.string().required(t("name.required")),
    email: Yup.string().email(t("email.invalid")).required(t("email.required")),
    password: Yup.string()
      .required(t("password.required"))
      .matches(/^(?=.*[a-zA-Z0-9])(?=.{6,})/, t("password.invalid")),
    password_confirmation: Yup.string()
      .required(t("password_confirmation.required"))
      .when("password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          t("password_confirmation.invalid")
        ),
      }),
    mobile: Yup.string()
      .test("valid-mobile", t("mobile.invalid"), (value) => {
        if (!value) {
          return false;
        }
        return isValidPhoneNumber(value);
      })
      .required(t("mobile.required"))
      .matches(/^(?=.*[0-9])/, t("mobile.space_values_not_valid")),
    residence: Yup.string().required(t("country_of_residence.required")),
    citizenship: Yup.string().required(t("country_of_citizenship.required")),
  });

  const validateMobileNumber = (value) => {
    if (value) {
      if (isPossiblePhoneNumber(value) == false) {
        formRef.current.setFieldError("mobile",t("mobile.invalid"));
        return false;
      } else if (isValidPhoneNumber(value) == false) {
        formRef.current.setFieldError("mobile", t("mobile.invalid"));
        return false;
      } else {
        return true;
      }
    } else {
      formRef.current.setFieldError("mobile", t("mobile.required"));
      return false;
    }
  };

  const handleRegister = (values) => {
    if (validateMobileNumber(values.mobile)) {
      const intlNo = formatPhoneNumberIntl(values.mobile);
      const countryCode = intlNo.substring(
        intlNo.indexOf("+") + 1,
        intlNo.indexOf(" ")
      );
      const mobile = intlNo
        .substring(intlNo.indexOf(" "), intlNo.length)
        .replaceAll(" ", "");
      dispatch(
        registerStart({
          ...values,
          mobile: mobile,
          country_code: countryCode,
        })
      );
    }
  };

  return (
    <>
      <Helmet>
        <link
          href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.1/dist/css/bootstrap.min.css"
          rel="stylesheet"
          integrity="sha384-4bw+/aepP/YC94hEpVNVgiZdgIC5+VKNBQNGCHeKRQN+PtmoHDEXuppvnDJzQIu9"
          crossorigin="anonymous"
        />
        <script
          src="https://cdn.jsdelivr.net/npm/bootstrap@5.3.1/dist/js/bootstrap.bundle.min.js"
          integrity="sha384-HwwvtgBNo3bZJJLYd8oVXjrBZt8cqVSpeBNS5n7C8IVInixGAoxmnlMuBnhbgrkm"
          crossorigin="anonymous"
        ></script>
      </Helmet>
      <div className="efi-auth-sec">
        <div className="container">
          <div className="header-logo">
            <div className="efi-logo-sec">
                <a href="https://efimarkets.com">
                  <img
                    className="efi-login"
                    src={configuration.get("configData.site_logo")}
                    alt="logo"
                  />
                </a>
            </div>
          </div>
        </div>
        <div className="efi-auth-item">
          <div className="container">
            <div className="row">
              <div className="col-md-7 resp-marg-btm-xs">
              </div>
              <div className="col-md-5">
                <div className="efi-auth-box">
                  <div className="efi-auth-title-sec">{t("message")}</div>
                  <Formik
                    initialValues={{
                      name: "",
                      email: "",
                      password: "",
                      password_confirmation: "",
                      mobile: "",
                      residence: "",
                      citizenship: "",
                      remember: false,
                    }}
                    validationSchema={registerSchema}
                    onSubmit={handleRegister}
                    innerRef={formRef}
                  >
                    {({ setFieldValue, values, touched, errors }) => (
                      <FORM className="efi-auth-form">
                        <div className="mb-3">
                          <label
                            for="exampleInputEmail1"
                            className="form-label"
                          >
                            Full Name
                          </label>
                          <Field
                            className="form-control"
                            placeholder={t("name.placeholder")}
                            type="text"
                            autoFocus={true}
                            name="name"
                          />
                          <ErrorMessage
                            component={"div"}
                            name="name"
                            className="errorMsg"
                          />
                        </div>
                        <div className="mb-3">
                          <label
                            for="exampleInputEmail1"
                            className="form-label"
                          >
                            Email Id
                          </label>
                          <Field
                            className="form-control"
                            placeholder={t("email.placeholder")}
                            type="text"
                            name="email"
                          />
                          <ErrorMessage
                            component={"div"}
                            name="email"
                            className="errorMsg"
                          />
                        </div>
                        <div className="mb-3 register-phone-input">
                          <label
                            htmlFor="mobile"
                            className="form-label"
                          >
                            Mobile No
                          </label>
                          <PhoneInput
                            name="mobile"
                            defaultCountry={defaultCountry}
                            placeholder={t("mobile.placeholder")}
                            onChange={(value) =>
                              setFieldValue("mobile", value)
                            }
                            onBlur={() => formRef.current.submitForm()}
                            international
                            className={`${
                              touched.mobile && errors.mobile
                                ? "is-invalid"
                                : ""
                            }`}
                          />
                          <ErrorMessage
                            component={"div"}
                            name="mobile"
                            className="errorMsg"
                          />
                        </div>
                        <div className="row mb-3">
                          <div className="col-md-6">
                            <label
                              for="exampleInputEmail1"
                              className="form-label"
                            >
                              Citizenship
                            </label>
                            <Field
                              className="form-control"
                              placeholder={t(
                                "country_of_citizenship.placeholder"
                              )}
                              type="text"
                              name="citizenship"
                            />
                            <ErrorMessage
                              component={"div"}
                              name="citizenship"
                              className="errorMsg"
                            />
                          </div>
                          <div className="col-md-6">
                            <label
                              for="exampleInputEmail1"
                              className="form-label"
                            >
                              Residence
                            </label>
                            <Field
                              className="form-control"
                              placeholder={t(
                                "country_of_residence.placeholder"
                              )}
                              type="text"
                              name="residence"
                            />
                            <ErrorMessage
                              component={"div"}
                              name="residence"
                              className="errorMsg"
                            />
                          </div>
                        </div>
                        <div className="row mb-3">
                          <div className="col-md-6">
                            <label
                              for="exampleInputEmail1"
                              className="form-label"
                            >
                              Password
                            </label>
                            <Field
                              className="form-control"
                              placeholder={t("password.placeholder")}
                              type="password"
                              name="password"
                            />
                            <ErrorMessage
                              component={"div"}
                              name="password"
                              className="errorMsg"
                            />
                          </div>
                          <div className="col-md-6">
                            <label
                              for="exampleInputEmail1"
                              className="form-label"
                            >
                              Confirm Password
                            </label>
                            <Field
                              className="form-control"
                              placeholder={t(
                                "password_confirmation.placeholder"
                              )}
                              type="password"
                              name="password_confirmation"
                            />
                            <ErrorMessage
                              component={"div"}
                              name="password_confirmation"
                              className="errorMsg"
                            />
                          </div>
                        </div>
                        {/* <div className="form-check form-switch mb-3">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            checked={values.remember}
                            onChange={() =>
                              setFieldValue("remember", !values.remember)
                            }
                          />
                          <label
                            className="form-check-label"
                            for="flexSwitchCheckDefault"
                          >
                            {t("remember")}
                          </label>
                        </div> */}
                        <div className="efi-ath-btn-sec">
                          <button
                            className="efi-auth-btn"
                            disabled={register.buttonDisable}
                          >
                            {!register.buttonDisable && (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                              >
                                <path d="M12 12a6 6 0 10-6-6 6.006 6.006 0 006 6zm0-10a4 4 0 11-4 4 4 4 0 014-4zM12 14a9.01 9.01 0 00-9 9 1 1 0 002 0 7 7 0 0114 0 1 1 0 002 0 9.01 9.01 0 00-9-9z"></path>
                              </svg>
                            )}
                            {register.buttonDisable ? (
                              <ButtonLoader varient="black" />
                            ) : (
                              t("register_btn.text")
                            )}
                          </button>
                        </div>
                      </FORM>
                    )}
                  </Formik>
                  <div className="efi-footer-link-sec">
                    <span>{t("have_account")}</span>
                    <Link to="/login">{t("login")}</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RegisterIndex;
