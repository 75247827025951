import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Navigate, useNavigate, useParams } from 'react-router-dom';
import { categoryDeleteStart, categoryStatusStart, categoryViewStart } from '../../store/slices/CategorySlice';
import { ButtonLoader } from '../Helper/Loader';
import PageLoader from '../Helper/PageLoader';
import SomethingWentWrong from '../Helper/SomethingWentWrong';
import { confirmAlert } from 'react-confirm-alert';
import { profileStart } from '../../store/slices/AdminSlice';

const CategoryView = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const profile = useSelector(state => state.admin.profile);
  const categoryView = useSelector(state => state.category.categoryView);
  const categoryStatus = useSelector(state => state.category.categoryStatus);
  const categoryDelete = useSelector(state => state.category.categoryDelete);
  const [skipRender, setSkipRender] = useState(true);

  useEffect(() => {
    if (!profile.loading && Object.keys(profile.data).length > 0)
      dispatch(categoryViewStart({ category_id: params.categoryId }))
  }, [profile]);

  useEffect(() => {
    if (!skipRender && !categoryDelete.loading && Object.keys(categoryDelete.data).length > 0)
      navigate("/categories");
    setSkipRender(false);
  }, [categoryDelete]);


  const remove = category => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="modal-content scrollbar-sm relative flex max-w-lg flex-col items-center overflow-y-auto rounded-lg bg-white px-4 py-10 text-center dark:bg-navy-700 sm:px-5">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="inline h-10 w-10 shrink-0 text-error">
              <path fillRule="evenodd" d="M16.5 4.478v.227a48.816 48.816 0 013.878.512.75.75 0 11-.256 1.478l-.209-.035-1.005 13.07a3 3 0 01-2.991 2.77H8.084a3 3 0 01-2.991-2.77L4.087 6.66l-.209.035a.75.75 0 01-.256-1.478A48.567 48.567 0 017.5 4.705v-.227c0-1.564 1.213-2.9 2.816-2.951a52.662 52.662 0 013.369 0c1.603.051 2.815 1.387 2.815 2.951zm-6.136-1.452a51.196 51.196 0 013.273 0C14.39 3.05 15 3.684 15 4.478v.113a49.488 49.488 0 00-6 0v-.113c0-.794.609-1.428 1.364-1.452zm-.355 5.945a.75.75 0 10-1.5.058l.347 9a.75.75 0 101.499-.058l-.346-9zm5.48.058a.75.75 0 10-1.498-.058l-.347 9a.75.75 0 001.5.058l.345-9z" clipRule="evenodd"></path>
            </svg>

            <div className="mt-4">
              <h2 className="text-2xl text-slate-700 dark:text-navy-100"> Delete </h2>
              <p className="mt-2"> Once you've deleted the record , the category ({category.name}) will no longer be available. This action cannot be undo. </p>
              <button
                onClick={onClose}
                className="btn mt-6 bg-info font-medium text-white hover:bg-info-focus focus:bg-info-focus active:bg-info-focus/90">
                Cancel
              </button>
              <button
                className="btn mt-6 bg-error font-medium text-white hover:bg-error-focus focus:bg-error-focus active:bg-error-focus/90"
                onClick={() => {
                  dispatch(categoryDeleteStart({ category_id: category.category_id }))
                  onClose();
                }}>
                Delete
              </button>
            </div>
          </div>
        );
      }
    })
  }

  return (
    profile.loading ?
      <PageLoader />
      : Object.keys(profile.data).length > 0 ?
        <>
          <div className="flex items-center space-x-4 py-5 lg:py-6">
            <h2 className="text-xl font-medium text-slate-800 dark:text-navy-50 lg:text-2xl"> Categories </h2>
            <div className="hidden h-full py-1 sm:flex">
              <div className="h-full w-px bg-slate-300 dark:bg-navy-600"></div>
            </div>
            <ul className="hidden flex-wrap items-center space-x-2 sm:flex">
              <li className="flex items-center space-x-2">
                <Link
                  to="/categories"
                  className="text-primary transition-colors hover:text-primary-focus dark:text-accent-light dark:hover:text-accent">
                  Categories
                </Link>
                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7"></path>
                </svg>
              </li>
              <li>View Category</li>
            </ul>
          </div>
          <div className="flex items-center justify-start gap-4 pb-2">
            <Link
              to="/categories/create"
              className="btn space-x-2 bg-primary font-medium text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                <path fillRule="evenodd" d="M3 6a3 3 0 013-3h2.25a3 3 0 013 3v2.25a3 3 0 01-3 3H6a3 3 0 01-3-3V6zm9.75 0a3 3 0 013-3H18a3 3 0 013 3v2.25a3 3 0 01-3 3h-2.25a3 3 0 01-3-3V6zM3 15.75a3 3 0 013-3h2.25a3 3 0 013 3V18a3 3 0 01-3 3H6a3 3 0 01-3-3v-2.25zm9.75 0a3 3 0 013-3H18a3 3 0 013 3V18a3 3 0 01-3 3h-2.25a3 3 0 01-3-3v-2.25z" clipRule="evenodd"></path>
              </svg>
              <span>Add Category</span>
            </Link>
            <Link
              to="/categories"
              className="btn space-x-2 bg-success font-medium text-white hover:bg-success-focus focus:bg-success-focus active:bg-success-focus/90">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                <path fillRule="evenodd" d="M3 6a3 3 0 013-3h2.25a3 3 0 013 3v2.25a3 3 0 01-3 3H6a3 3 0 01-3-3V6zm9.75 0a3 3 0 013-3H18a3 3 0 013 3v2.25a3 3 0 01-3 3h-2.25a3 3 0 01-3-3V6zM3 15.75a3 3 0 013-3h2.25a3 3 0 013 3V18a3 3 0 01-3 3H6a3 3 0 01-3-3v-2.25zm9.75 0a3 3 0 013-3H18a3 3 0 013 3V18a3 3 0 01-3 3h-2.25a3 3 0 01-3-3v-2.25z" clipRule="evenodd"></path>
              </svg>
              <span>View Categories</span>
            </Link>
          </div>
          {categoryView.loading ?
            <PageLoader />
            : Object.keys(categoryView.data).length > 0 ?
              <div className="card px-4 pb-4 sm:px-5">
                <div className="my-5 flex h-8 items-center justify-end">
                  <label className="flex items-center space-x-2">
                    <Link
                      to={`/categories/edit/${categoryView.data.category.category_id}`}
                      className="btn h-9 w-9 rounded-full bg-info p-0 font-medium text-white hover:bg-info-focus hover:shadow-lg hover:shadow-info/50 focus:bg-info-focus active:bg-info-focus/90"
                      data-tooltip-id="tooltip-info"
                      data-tooltip-content="Edit"
                      data-tooltip-place="top"
                    >
                      <i className="fa-solid fa-edit"></i>
                    </Link>
                    {categoryDelete.buttonDisable && categoryDelete.data.category_id === categoryView.data.category.category_id ?
                      <ButtonLoader varient="error" />
                      : <button
                        className="btn h-9 w-9 rounded-full bg-error p-0 font-medium text-white hover:bg-error-focus hover:shadow-lg hover:shadow-error/50 focus:bg-error-focus active:bg-error-focus/90"
                        onClick={() => remove(categoryView.data.category)}
                        data-tooltip-id="tooltip-error"
                        data-tooltip-content="Delete"
                        data-tooltip-place="top"
                      >
                        <i className="fa-solid fa-trash"></i>
                      </button>
                    }
                    <Link
                      to={`/sub_categories?search_key=${categoryView.data.category.name}`}
                      className="btn h-9 w-9 rounded-full bg-success p-0 font-medium text-white hover:bg-success-focus hover:shadow-lg hover:shadow-success/50 focus:bg-success-focus active:bg-success-focus/90"
                      data-tooltip-id="tooltip-success"
                      data-tooltip-content="Sub Categories"
                      data-tooltip-place="top"
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                        <path fillRule="evenodd" d="M3.5 2A1.5 1.5 0 002 3.5V15a3 3 0 106 0V3.5A1.5 1.5 0 006.5 2h-3zm11.753 6.99L9.5 14.743V6.257l1.51-1.51a1.5 1.5 0 012.122 0l2.121 2.121a1.5 1.5 0 010 2.122zM8.364 18H16.5a1.5 1.5 0 001.5-1.5v-3a1.5 1.5 0 00-1.5-1.5h-2.136l-6 6zM5 16a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd"></path>
                      </svg>
                    </Link>
                  </label>
                </div>
                <div className="grid grid-cols-2 gap-4 sm:gap-5 lg:gap-6">
                  <div>
                    <div className="mt-2">
                      <div className="is-scrollbar-hidden min-w-full overflow-x-auto rounded-lg border border-slate-200 dark:border-navy-500">
                        <table className="w-full text-left">
                          <thead>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="whitespace-nowrap text-sm font-semibold border border-slate-200 px-3 py-3 dark:border-navy-500 lg:px-5">
                                Name
                              </td>
                              <td className="whitespace-nowrap border border-slate-200 px-3 py-3 dark:border-navy-500 lg:px-5">
                                {categoryView.data.category.name}
                              </td>
                            </tr>

                            <tr>
                              <td className="whitespace-nowrap text-sm font-semibold border border-slate-200 px-3 py-3 dark:border-navy-500 lg:px-5">
                                Sub Categories
                              </td>
                              <td className="whitespace-nowrap border border-slate-200 px-3 py-3 dark:border-navy-500 lg:px-5">
                                <Link to={`/sub_categories?search_key=${categoryView.data.category.name}`}
                                  className="badge rounded-full border border-info text-info">
                                  {categoryView.data.category.sub_categories_count}
                                </Link>
                              </td>
                            </tr>
                            <tr>
                              <td className="whitespace-nowrap text-sm font-semibold border border-slate-200 px-3 py-3 dark:border-navy-500 lg:px-5">
                                Created at
                              </td>
                              <td className="whitespace-nowrap border border-slate-200 px-3 py-3 dark:border-navy-500 lg:px-5">
                                {categoryView.data.category.created_at}
                              </td>
                            </tr>

                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="mt-2">
                      <div className="is-scrollbar-hidden min-w-full overflow-x-auto rounded-lg border border-slate-200 dark:border-navy-500">
                        <table className="w-full text-left">
                          <thead>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="whitespace-nowrap text-sm font-semibold border border-slate-200 px-3 py-3 dark:border-navy-500 lg:px-5">
                                Unique Id
                              </td>
                              <td className="whitespace-nowrap border border-slate-200 px-3 py-3 dark:border-navy-500 lg:px-5">
                                {categoryView.data.category.category_unique_id}
                              </td>
                            </tr>
                            <tr>
                              <td className="whitespace-nowrap text-sm font-semibold border border-slate-200 px-3 py-3 dark:border-navy-500 lg:px-5">
                                Status
                              </td>
                              <td className="whitespace-nowrap border border-slate-200 dark:border-navy-500 lg:px-5">
                                <div className="avatar">
                                  <label className="inline-flex items-center space-x-2">
                                    {categoryView.data.category.status == 1 ?
                                      <div className="badge space-x-2.5 rounded-full bg-success/10 text-success dark:bg-success/15" id="statusBadge156">
                                        <div className="h-2 w-2 rounded-full bg-current"></div>
                                        <span id="statusText156">Approved</span>
                                      </div>
                                      : <div className="badge space-x-2.5 rounded-full bg-error/10 text-error dark:bg-error/15" id="statusBadge19">
                                        <div className="h-2 w-2 rounded-full bg-current"></div>
                                        <span id="statusText19">Declined</span>
                                      </div>
                                    }
                                    <input
                                      className="form-switch is-outline h-5 w-10 rounded-full border border-slate-400/70 bg-transparent before:rounded-full before:bg-slate-300 checked:!border-success checked:before:!bg-success dark:border-navy-400 dark:before:bg-navy-300"
                                      type="checkbox"
                                      checked={categoryView.data.category.status == 1}
                                      onChange={() => dispatch(categoryStatusStart({ category_id: categoryView.data.category.category_id }))}
                                      disabled={categoryStatus.buttonDisable}
                                    />
                                    {categoryStatus.buttonDisable && categoryStatus.data.category_id === categoryView.data.category.category_id ?
                                      <ButtonLoader />
                                      : null
                                    }
                                  </label>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td className="whitespace-nowrap text-sm font-semibold border border-slate-200 px-3 py-3 dark:border-navy-500 lg:px-5">
                                Updated at
                              </td>
                              <td className="whitespace-nowrap border border-slate-200 px-3 py-3 dark:border-navy-500 lg:px-5">
                                {categoryView.data.category.updated_at}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="is-scrollbar-hidden min-w-full overflow-x-auto rounded-lg border border-slate-200 dark:border-navy-500 my-5 p-5">
                  <div className="grid grid-cols-2 gap-4 sm:gap-5 lg:gap-6">
                    <div>
                      <h2 className="text-2xl pb-2">Description</h2>
                      {categoryView.data.category.description}
                    </div>
                    <div>
                      <img
                        className="h-48 p-3 w-full shrink-0 rounded-t-lg bg-cover bg-center object-cover object-center lg:h-auto lg:w-48 lg:rounded-t-none lg:rounded-l-lg"
                        src={categoryView.data.category.picture}
                        alt="image"
                      />
                    </div>
                  </div>
                </div>
              </div>
              : <SomethingWentWrong buttonText="Retry" handleClick={() => dispatch(categoryViewStart({ category_id: params.categoryId }))} />
          }
        </>
        : <SomethingWentWrong buttonText="Retry" handleClick={() => dispatch(profileStart())} />
  );
}

export default CategoryView;