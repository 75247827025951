import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setTheme } from '../../store/slices/AdminSlice';
import {
  setTranslations,
  setDefaultLanguage,
  translate,
  setLanguage,
  withTranslation
} from "react-multi-lang";
import en from "../translations/en.json";
import es from "../translations/es.json";
// Layouts
import { AuthLayout, EmptyLayout, AdminLayout } from '../Layout';
// Pages
import Page404 from '../Helper/Page404';
import Login from '../Auth/Login';
import Register from '../Auth/Register';
import ForgotPassword from '../Auth/ForgotPassword';
import ResetPassword from '../Auth/ResetPassword';
import DashboardIndex from '../Dashboard/DashboardIndex';
import CategoryIndex from '../Category/CategoryIndex';
import CategoryForm from '../Category/CategoryForm';
import CategoryView from '../Category/CategoryView';
import ProfileIndex from '../Profile/ProfileIndex';
import PaymentForm from '../Payment/PaymentForm';
import PaymentIndex from '../Payment/PaymentIndex';
import PaymentView from "../Payment/PaymentView"
import LoginIndex from '../Auth/LoginIndex';
import RegisterIndex from '../Auth/RegisterIndex';
import ForgotPasswordIndex from '../Auth/ForgotPasswordIndex';
import ResetPasswordIndex from "../Auth/ResetPasswordIndex"

setTranslations({ en, es });

const CommonRoute = ({
  component: Component,
  authLayout: AuthLayout,
  adminLayout: AdminLayout,
  ...rest
}) => localStorage.getItem("token") || sessionStorage.getItem("token") ?
    <AdminLayout {...rest}>
      <Component />
    </AdminLayout>
    : <AuthLayout {...rest}>
      <Component />
    </AuthLayout>

const AppRoute = ({
  component: Component,
  layout: Layout,
  ...rest
}) => localStorage.getItem("token") || sessionStorage.getItem("token") ?
    <Navigate to={{ pathname: "/" }} />
    : <Layout {...rest}>
      <Component />
    </Layout>;

const PrivateRoute = ({
  component: Component,
  layout: Layout,
  screenProps: ScreenProps,
  ...rest
}) => localStorage.getItem("token") || sessionStorage.getItem("token") ?
    <Layout screenProps={ScreenProps} {...rest}>
      <Component authRoute={true} />
    </Layout>
    : <Navigate to={{ pathname: "/login" }} />;

const Base = () => {
  const dispatch = useDispatch();
  const theme = useSelector(state => state.admin.theme);
  const profile = useSelector(state => state.admin.profile);
  const settingsJson = useSelector((state) => state.admin.settingsJson);

  const [, setState] = useState();

  useEffect(() => {
    if (localStorage.getItem("dark-mode"))
      dispatch(setTheme(localStorage.getItem("dark-mode")));
  }, []);

  useEffect(() => {
    if (theme === "dark") {
      document.documentElement.classList.add("dark");
      localStorage.setItem("dark-mode", theme)
    } else {
      document.documentElement.classList.remove("dark");
      localStorage.removeItem("dark-mode");
    }
  }, [theme]);

  useEffect(() => {
    let userLanguage = localStorage.getItem("lang")
      ? localStorage.getItem("lang")
      : "en";
    localStorage.setItem("lang", userLanguage);
    setLanguage(userLanguage);
  }, []);

  useEffect(() => {
    setState(1);
  }, [profile]);

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route
            path={"/login"}
            element={
              <AppRoute
                component={LoginIndex}
                layout={AuthLayout}
              />
            }
          />
          <Route
            path={"/register"}
            element={
              <AppRoute
                component={RegisterIndex}
                layout={AuthLayout}
              />
            }
          />
          <Route
            path={"/forgot-password"}
            element={
              <AppRoute
                component={ForgotPasswordIndex}
                layout={AuthLayout}
              />
            }
          />
          <Route
            path={"/reset-password/:token"}
            element={
              <AppRoute
                component={ResetPasswordIndex}
                layout={AuthLayout}
              />
            }
          />
          <Route
            path={"/"}
            element={
              <PrivateRoute
                component={DashboardIndex}
                layout={AdminLayout}
              />
            }
          />

          {/* Payment */}
          <Route path={"/payment"}>
            <Route
              index={true}
              element={
                <PrivateRoute
                  component={PaymentIndex}
                  layout={AdminLayout}
                />
              }
            />
            <Route
              path={"create"}
              element={
                <PrivateRoute
                  component={PaymentForm}
                  layout={AdminLayout}
                />
              }
            />
            <Route
              path={"show/:paymentId"}
              element={
                <PrivateRoute
                  component={PaymentView}
                  layout={AdminLayout}
                />
              }
            />
          </Route>

          <Route
            path={"/profile"}
            element={
              <PrivateRoute
                component={ProfileIndex}
                layout={AdminLayout}
              />
            }
          />

          {/* CATEGORY */}
          <Route path={"/categories"}>
            <Route
              index={true}
              element={
                <PrivateRoute
                  component={CategoryIndex}
                  layout={AdminLayout}
                />
              }
            />
            <Route
              path={"create"}
              element={
                <PrivateRoute
                  component={CategoryForm}
                  layout={AdminLayout}
                />
              }
            />
            <Route
              path={"show/:categoryId"}
              element={
                <PrivateRoute
                  component={CategoryView}
                  layout={AdminLayout}
                />
              }
            />
            <Route
              path={"edit/:categoryId"}
              element={
                <PrivateRoute
                  component={CategoryForm}
                  layout={AdminLayout}
                />
              }
            />
          </Route>

          <Route
            path={"/*"}
            element={
              <CommonRoute
                component={Page404}
                authLayout={EmptyLayout}
                adminLayout={AdminLayout}
              />
            }
          />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default Base;