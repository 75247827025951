import React, { useEffect, useState } from "react";
import Base from "./components/Base";
import { Provider } from "react-redux";
import store from "./store";
import { ToastContainer } from "react-toastify";
import { Tooltip } from "react-tooltip";
import configuration from "react-global-configuration";
import PageLoader from "./components/Helper/PageLoader";
import { apiUrl } from "./Environment";

//Css
import "react-toastify/dist/ReactToastify.css";
import "react-confirm-alert/src/react-confirm-alert.css";
import "react-tooltip/dist/react-tooltip.css";
import "react-phone-number-input/style.css";

function App() {
  const [configLoading, setConfigLoading] = useState(true);

  useEffect(() => {
    fetchConfig();
  }, []);

  const fetchConfig = async () => {
    try {
      const response = await fetch(
        `${apiUrl}get_settings_json`
      );
      const configValue = await response.json();
      configuration.set({ configData: configValue.data }, { freeze: false });
      setConfigLoading(false);
    } catch (error) {
      configuration.set({ configData: [] }, { freeze: false });
      setConfigLoading(false);
    }
  };

  return (
    <>
      <Provider store={store}>
        <ToastContainer />
        <Tooltip id="tooltip-secondary" className="secondary" />
        <Tooltip id="tooltip-info" className="info" />
        <Tooltip id="tooltip-error" className="error" />
        <Tooltip id="tooltip-success" className="success" />
        {configLoading ? <PageLoader /> : <Base />}
      </Provider>
    </>
  );
}

export default App;
