import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { profileStart } from "../../store/slices/AdminSlice";
import PageLoader from "../Helper/PageLoader";
import SomethingWentWrong from "../Helper/SomethingWentWrong";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
  paymentSaveStart,
  paymentViewStart,
  billingAccountsStart,
} from "../../store/slices/PaymentSlice";
import { ButtonLoader } from "../Helper/Loader";

const PaymentForm = () => {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.admin.profile);
  const paymentView = useSelector((state) => state.payment.paymentView);
  const paymentSave = useSelector((state) => state.payment.paymentSave);
  const billingAccounts = useSelector((state) => state.payment.billingAccounts);
  const [skipRender, setSkipRender] = useState(true);
  const [image, setImage] = useState("");
  const [previewImage, setPreviewImage] = useState("");
  const [selectedAccount, setSelectedAccount] = useState({});

  const validationSchema = Yup.object().shape({
    transaction_id: Yup.string()
      .required("Transaction ID is required")
      .test(
        "len",
        "Should not exceed 255 characters",
        (val) => val && val.toString().length < 255
      ),
    amount: Yup.string().required("Amount is required").min(0),
    billing_account_id: Yup.string().required("Billing Account is required"),
  });

  useEffect(() => {
    dispatch(billingAccountsStart());
  }, []);

  useEffect(() => {
    if (
      !profile.loading &&
      Object.keys(profile.data).length > 0 &&
      params.paymentId
    )
      dispatch(paymentViewStart({ payment_id: params.paymentId }));
  }, [profile]);

  useEffect(() => {
    if (
      !skipRender &&
      !paymentView.loading &&
      Object.keys(paymentView.data).length > 0
    )
      setPreviewImage(paymentView.data.payment.picture);
  }, [paymentView]);

  useEffect(() => {
    let objectUrl;
    if (image) {
      objectUrl = URL.createObjectURL(image);
      setPreviewImage(objectUrl);
    }
    return () => URL.revokeObjectURL(objectUrl);
  }, [image]);

  const handleSubmit = (values) => {
    let data = values;
    if (params.paymentId) data = { ...data, payment_id: params.paymentId };
    if (image) data = { ...data, picture: image };
    dispatch(paymentSaveStart(data));
  };

  useEffect(() => {
    if (!skipRender && Object.keys(paymentSave.data).length > 0)
      navigate(`/payment/show/${paymentSave.data.user_payment.id}`);
    setSkipRender(false);
  }, [paymentSave]);

  return profile.loading ? (
    <PageLoader />
  ) : Object.keys(profile.data).length > 0 ? (
    <>
      {/* HEADER */}
      <div className="flex items-center space-x-4 py-5 lg:py-6">
        <h2 className="text-xl font-medium text-slate-800 dark:text-navy-50 lg:text-2xl">
          {" "}
          Payments{" "}
        </h2>
        <div className="hidden h-full py-1 sm:flex">
          <div className="h-full w-px bg-slate-300 dark:bg-navy-600"></div>
        </div>
        <ul className="hidden flex-wrap items-center space-x-2 sm:flex">
          <li className="flex items-center space-x-2">
            <Link
              to="/payment"
              className="text-primary transition-colors hover:text-primary-focus dark:text-accent-light dark:hover:text-accent"
            >
              Payments
            </Link>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-4 w-4"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M9 5l7 7-7 7"
              ></path>
            </svg>
          </li>
          <li>{params.paymentId ? "Edit" : "Add"} payment</li>
        </ul>
      </div>
      <div className="flex items-center justify-between pb-2">
        <Link
          to="/payment"
          className="btn space-x-2 bg-primary font-medium text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            className="w-6 h-6"
          >
            <path
              fillRule="evenodd"
              d="M3 6a3 3 0 013-3h2.25a3 3 0 013 3v2.25a3 3 0 01-3 3H6a3 3 0 01-3-3V6zm9.75 0a3 3 0 013-3H18a3 3 0 013 3v2.25a3 3 0 01-3 3h-2.25a3 3 0 01-3-3V6zM3 15.75a3 3 0 013-3h2.25a3 3 0 013 3V18a3 3 0 01-3 3H6a3 3 0 01-3-3v-2.25zm9.75 0a3 3 0 013-3H18a3 3 0 013 3V18a3 3 0 01-3 3h-2.25a3 3 0 01-3-3v-2.25z"
              clipRule="evenodd"
            ></path>
          </svg>
          <span>View Payments</span>
        </Link>
      </div>

      {/* FORM */}
      {params.paymentId && paymentView.loading ? (
        <PageLoader />
      ) : Object.keys(paymentView.data).length > 0 || !params.paymentId ? (
        <div className="card p-5 pb-4">
          <div className="px-2 py-2 sm:px-6">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              {params.paymentId ? "Edit" : "Add"} payment
            </h3>
          </div>
          <div className="grid grid-cols-12 gap-4 sm:gap-5 lg:gap-6">
            <div className="col-span-12 lg:col-span-12">
              <Formik
                initialValues={{
                  transaction_id: params.paymentId
                    ? paymentView.data.payment.transaction_id
                    : "",
                  amount: params.paymentId
                    ? paymentView.data.payment.amount
                    : "",
                  billing_account_id: params.paymentId
                    ? paymentView.data.payment.billing_account_id
                    : "",
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({ values, setFieldValue }) => (
                  <FORM className="form">
                    <div className="">
                      <div className="flex flex-col">
                        <div className="p-4 sm:p-5">
                          <div className="space-y-5">
                            <div class="flex flex-row gap-6">
                              <div class="w-1/2">
                                <label className="block">
                                  <span className="font-medium text-slate-600 dark:text-navy-100">
                                    Transaction ID *
                                  </span>
                                  <Field
                                    className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                    placeholder="Enter Transaction ID"
                                    name="transaction_id"
                                    autoFocus={true}
                                  />
                                </label>
                                <span className="text-tiny+ text-slate-400 dark:text-navy-300">
                                  <ErrorMessage
                                    component={"div"}
                                    name="transaction_id"
                                    className="errorMsg w-100"
                                  />
                                </span>
                              </div>
                              <div class="w-1/2">
                                <label className="block">
                                  <span className="font-medium text-slate-600 dark:text-navy-100">
                                    Amount *
                                  </span>
                                  <Field
                                    className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                    placeholder="Enter Amount"
                                    name="amount"
                                    autoFocus={true}
                                    type="number"
                                    min={0}
                                  />
                                </label>
                                <span className="text-tiny+ text-slate-400 dark:text-navy-300">
                                  <ErrorMessage
                                    component={"div"}
                                    name="amount"
                                    className="errorMsg w-100"
                                  />
                                </span>
                              </div>
                            </div>
                            <div class="flex flex-row gap-6">
                              <div class="w-1/2">
                                <label className="block">
                                  <span className="font-medium text-slate-600 dark:text-navy-100">
                                    Billing Accounts *
                                  </span>
                                  {billingAccounts.loading ? null : Object.keys(
                                      billingAccounts.data
                                    ).length > 0 &&
                                    billingAccounts.data.billing_accounts
                                      .length > 0 ? (
                                    <select
                                      className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                      name="type"
                                      id="tomTwo"
                                      required
                                      onChange={(e) => {
                                        setFieldValue(
                                          "billing_account_id",
                                          e.target.value
                                        );
                                        setSelectedAccount(
                                          billingAccounts.data.billing_accounts.find(
                                            (billing_account) =>
                                              billing_account.id ==
                                              e.target.value
                                          )
                                        );
                                      }}
                                    >
                                      <option value="">
                                        Select Billing Account
                                      </option>
                                      {billingAccounts.data.billing_accounts.map(
                                        (billing_account) => (
                                          <option value={billing_account.id}>
                                            {billing_account.account_number +
                                              " - " +
                                              billing_account.bank_name}
                                          </option>
                                        )
                                      )}
                                    </select>
                                  ) : null}
                                </label>
                                <span className="text-tiny+ text-slate-400 dark:text-navy-300">
                                  <ErrorMessage
                                    component={"div"}
                                    name="billing_account_id"
                                    className="errorMsg w-100"
                                  />
                                </span>
                                {console.log(
                                  "selectedAccount",
                                  selectedAccount
                                )}
                                {Object.keys(selectedAccount).length > 0 && (
                                  <div className="container">
                                    <div class="flex flex-row gap-6 bank-details">
                                      <div class="">
                                        <h5><strong>Bank Account: </strong> <span>{selectedAccount.account_number}</span></h5>
                                        <h5><strong>Bank Name: </strong> <span>{selectedAccount.bank_name}</span></h5>
                                      {/* </div>
                                      <div class="w-1/2"> */}
                                        <h5><strong>IFSC Code: </strong> <span>{selectedAccount.ifsc_code}</span></h5>
                                        <h5><strong>Account Type: </strong> <span>{selectedAccount.bank_type}</span></h5>
                                        <h5><strong>First Name: </strong> <span>{selectedAccount.first_name ? selectedAccount.first_name : "N/A"}</span></h5>
                                        <h5><strong>Last Name: </strong> <span>{selectedAccount.last_name ? selectedAccount.last_name : "N/A"}</span></h5>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                              <div class="w-1/2">
                                <div className="pb-5">
                                  <span className="font-medium text-slate-600 dark:text-navy-100">
                                    {" "}
                                    Picture
                                  </span>
                                  <label className="btn relative bg-primary font-medium text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90">
                                    <input
                                      type="file"
                                      className="pointer-events-none absolute inset-0 h-full w-full opacity-0"
                                      accept=".png, .jpg, .jpeg"
                                      onChange={(e) =>
                                        setImage(e.target.files[0])
                                      }
                                    />
                                    <div className="flex items-center space-x-2">
                                      <i className="fa-solid fa-cloud-arrow-up text-base"></i>
                                      <span>Choose File</span>
                                    </div>
                                  </label>
                                </div>
                                <div className="avatar h-24 w-24">
                                  <img
                                    className="rounded-lg"
                                    src={
                                      previewImage
                                        ? previewImage
                                        : window.location.origin +
                                          "/images/cat-placeholder.jpg"
                                    }
                                    alt="avatar"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex justify-end space-x-2">
                      <Link
                        to="/payment"
                        className="btn min-w-[7rem] rounded-full border font-medium text-slate-700 hover:bg-slate-150 focus:bg-slate-150 active:bg-slate-150/80 dark:border-navy-450 dark:text-navy-100 dark:hover:bg-navy-500 dark:focus:bg-navy-500 dark:active:bg-navy-500/90"
                      >
                        Cancel
                      </Link>
                      <button
                        type="submit"
                        className="btn min-w-[7rem] rounded-full bg-success font-medium text-white hover:bg-success-focus focus:bg-success-focus active:bg-success-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90"
                        disabled={paymentSave.buttonDisable}
                      >
                        {paymentSave.buttonDisable ? (
                          <ButtonLoader varient="info" />
                        ) : (
                          "Submit"
                        )}
                      </button>
                    </div>
                  </FORM>
                )}
              </Formik>
            </div>
          </div>
        </div>
      ) : (
        <SomethingWentWrong
          buttonText="Retry"
          handleClick={() =>
            dispatch(paymentViewStart({ payment_id: params.paymentId }))
          }
        />
      )}
    </>
  ) : (
    <SomethingWentWrong
      buttonText="Retry"
      handleClick={() => dispatch(profileStart())}
    />
  );
};

export default PaymentForm;
