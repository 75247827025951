import { call, select, put, takeLatest, all } from "redux-saga/effects";
import { getCommonSaga } from "../sagaUtils";
import store from '../index';
import {
  paymentBulkActionFailure,
  paymentBulkActionSuccess,
  paymentDeleteFailure,
  paymentDeleteSuccess,
  paymentListFailure,
  paymentListSuccess,
  paymentSaveFailure,
  paymentSaveSuccess,
  paymentStatusFailure,
  paymentStatusSuccess,
  paymentViewFailure,
  paymentViewSuccess,
  billingAccountsFailure,
  billingAccountsSuccess,
} from "../slices/PaymentSlice";


function* paymentListAPI(action) {
  yield getCommonSaga({
    apiUrl: "user_payments",
    payload: action.payload,
    successNotify: false,
    success: paymentListSuccess,
    failure: paymentListFailure,
  });
}

function* paymentStatusAPI(action) {
  const user_paymentsListData = yield select(state => state.payment.paymentList.data);
  const paymentViewData = yield select(state => state.payment.paymentView.data);
  yield getCommonSaga({
    apiUrl: "user_payments/status",
    payload: action.payload,
    successCallback: true,
    success: data => {
      store.dispatch(paymentStatusSuccess(data));
      if (Object.keys(user_paymentsListData).length > 0)
        store.dispatch(paymentListSuccess({
          ...user_paymentsListData,
          user_payments: user_paymentsListData.user_payments.map((payment) => payment.payment_id === action.payload.payment_id ? data.payment : payment),
        }));
      if (Object.keys(paymentViewData).length > 0 && paymentViewData.payment.payment_id === action.payload.payment_id)
        store.dispatch(paymentViewSuccess(data));
    },
    failure: paymentStatusFailure,
  })
}

function* paymentBulkActionAPI(action) {
  const user_paymentsListData = yield select(state => state.payment.paymentList.data);
  const { paymentIds, ...payload } = action.payload;
  yield getCommonSaga({
    apiUrl: "user_payments/bulk_action",
    payload: payload,
    successCallback: true,
    success: data => {
      store.dispatch(paymentBulkActionSuccess({ data: data }));
      if (payload.action_type === 3)
        store.dispatch(paymentListSuccess(data))
      else
        store.dispatch(paymentListSuccess({
          ...user_paymentsListData,
          user_payments: user_paymentsListData.user_payments.map(payment => paymentIds.includes(payment.payment_id) ?
            { ...payment, status: payload.action_type === 1 ? 1 : 0 }
            : payment
          )
        }))
    },
    failure: paymentBulkActionFailure,
  })
}

function* paymentDeleteAPI(action) {
  const user_paymentsListData = yield select(state => state.payment.paymentList.data);
  yield getCommonSaga({
    apiUrl: "user_payments/destroy",
    payload: action.payload,
    successCallback: true,
    success: data => {
      store.dispatch(paymentDeleteSuccess({ data: data }));
      if (Object.keys(user_paymentsListData).length > 0)
        store.dispatch(paymentListSuccess({
          user_payments: [
            ...user_paymentsListData.user_payments.filter(cat => cat.payment_id !== action.payload.payment_id),
            ...data.user_payments,
          ],
          total_user_payments: data.total_user_payments,
        }))
    },
    failure: paymentDeleteFailure
  })
}

function* paymentViewAPI(action) {
  yield getCommonSaga({
    apiUrl: "user_payments/show",
    payload: action.payload,
    successNotify: false,
    success: paymentViewSuccess,
    failure: paymentViewFailure,
  });
}

function* paymentSaveAPI(action) {
  yield getCommonSaga({
    apiUrl: "user_payments/store",
    payload: action.payload,
    successNotify: false,
    success: paymentSaveSuccess,
    failure: paymentSaveFailure,
  });
}

function* billingAccountsAPI(action) {
  yield getCommonSaga({
    apiUrl: "billing_accounts",
    payload: action.payload,
    successNotify: false,
    success: billingAccountsSuccess,
    failure: billingAccountsFailure,
  });
}

export default function* PaymentSaga() {
  yield all([
    yield takeLatest('payment/paymentListStart', paymentListAPI),
    yield takeLatest('payment/paymentStatusStart', paymentStatusAPI),
    yield takeLatest('payment/paymentBulkActionStart', paymentBulkActionAPI),
    yield takeLatest('payment/paymentDeleteStart', paymentDeleteAPI),
    yield takeLatest('payment/paymentViewStart', paymentViewAPI),
    yield takeLatest('payment/paymentSaveStart', paymentSaveAPI),
    yield takeLatest('payment/billingAccountsStart', billingAccountsAPI),
  ]);
}

