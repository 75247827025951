import React from 'react';
import AdminSidebar from './Header/AdminSidebar';
import AdminHeader from './Header/AdminHeader';

const AuthLayout = ({ children }) => <>{children}</>;

const EmptyLayout = ({ children }) => <>{children}</>;

const AdminLayout = ({ children }) => {
  return (
    <>
      <AdminSidebar />
      <AdminHeader />
      <main className="main-content w-full px-[var(--margin-x)] pb-8">
        {children}
      </main>
    </>
  );
}

export { AuthLayout, EmptyLayout, AdminLayout };