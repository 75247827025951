import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import PageLoader from '../Helper/PageLoader';
import SomethingWentWrong from '../Helper/SomethingWentWrong';
import { profileStart } from '../../store/slices/AdminSlice';
import { categoryBulkActionStart, categoryDeleteStart, categoryListStart, categoryStatusStart } from '../../store/slices/CategorySlice';
import { ButtonLoader } from '../Helper/Loader';
import Pagination from '../Helper/Pagination';
import { Formik, Field, Form } from 'formik';
import { confirmAlert } from 'react-confirm-alert';
import { getErrorNotificationMessage } from '../Helper/NotificationMessage';

const CategoryIndex = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const profile = useSelector(state => state.admin.profile);
  const categoryList = useSelector(state => state.category.categoryList);
  const categoryStatus = useSelector(state => state.category.categoryStatus);
  const categoryDelete = useSelector(state => state.category.categoryDelete);
  const [skipRender, setSkipRender] = useState(true);
  const [showBulkAction, setShowBulkAction] = useState(false);
  const [filterStatus, setFilterStatus] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [page, setPage] = useState(searchParams.get("page") ? searchParams.get("page") : 1);
  const [filter, setFilter] = useState({
    search_key: searchParams.get("search_key") ? searchParams.get("search_key") : "",
    status: searchParams.get("status") ? searchParams.get("status") : "",
  });

  useEffect(() => {
    if (!profile.loading && Object.keys(profile.data).length > 0) {
      dispatch(categoryListStart({
        ...filter,
        skip: 12 * (page - 1),
        take: 12,
      }));
    }
  }, [profile, page, filter]);

  useEffect(() => {
    setSelectedCategories([]);
  }, [categoryList]);

  useEffect(() => {
    if (!skipRender) {
      setPage(searchParams.get("page") ? searchParams.get("page") : 1);
      setFilter({
        search_key: searchParams.get("search_key") ? searchParams.get("search_key") : "",
        status: searchParams.get("status") ? searchParams.get("status") : "",
      })
    }
    setSkipRender(false);
  }, [searchParams.get("page"), searchParams.get("search_key"), searchParams.get("status")]);

  const handlePageClick = ({ selected }) => {
    let params = searchParams.get("search_key") ? `search_key=${searchParams.get("search_key")}&` : "";
    params += searchParams.get("status") ? `status=${searchParams.get("status")}&` : "";
    navigate(`/categories?${params}page=${selected + 1}`);
  }

  const bulkAction = type => {
    if (selectedCategories.length > 0)
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="modal-content scrollbar-sm relative flex max-w-lg flex-col items-center overflow-y-auto rounded-lg bg-white px-4 py-10 text-center dark:bg-navy-700 sm:px-5">
              <p>Are you sure to {type === 1 ? "approve" : type === 2 ? "decline" : "delete"} these records ?</p>
              <div className="s">
                <button
                  onClick={() => {
                    const categoryIds = selectedCategories.map(cat => cat.category_id);
                    let data = {
                      categoryIds,
                      model_ids: categoryIds.join(','),
                      action_type: type,
                    };
                    if (type === 3)
                      data = {
                        ...data,
                        ...filter,
                        skip: 12 * (page - 1),
                        take: 12,
                      }
                    dispatch(categoryBulkActionStart(data));
                    onClose();
                  }}
                  className="btn mt-6 mr-5 bg-info font-medium text-white hover:bg-info-focus focus:bg-info-focus active:bg-info-focus/90">
                  Yes
                </button>
                <button
                  onClick={onClose}
                  className="btn mt-6 bg-error font-medium text-white hover:bg-error-focus focus:bg-error-focus active:bg-error-focus/90">
                  No
                </button>
              </div>
            </div >
          );
        }
      });
    else getErrorNotificationMessage("please select atleast one row");
  }

  const remove = category => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="modal-content scrollbar-sm relative flex max-w-lg flex-col items-center overflow-y-auto rounded-lg bg-white px-4 py-10 text-center dark:bg-navy-700 sm:px-5">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="inline h-10 w-10 shrink-0 text-error">
              <path fillRule="evenodd" d="M16.5 4.478v.227a48.816 48.816 0 013.878.512.75.75 0 11-.256 1.478l-.209-.035-1.005 13.07a3 3 0 01-2.991 2.77H8.084a3 3 0 01-2.991-2.77L4.087 6.66l-.209.035a.75.75 0 01-.256-1.478A48.567 48.567 0 017.5 4.705v-.227c0-1.564 1.213-2.9 2.816-2.951a52.662 52.662 0 013.369 0c1.603.051 2.815 1.387 2.815 2.951zm-6.136-1.452a51.196 51.196 0 013.273 0C14.39 3.05 15 3.684 15 4.478v.113a49.488 49.488 0 00-6 0v-.113c0-.794.609-1.428 1.364-1.452zm-.355 5.945a.75.75 0 10-1.5.058l.347 9a.75.75 0 101.499-.058l-.346-9zm5.48.058a.75.75 0 10-1.498-.058l-.347 9a.75.75 0 001.5.058l.345-9z" clipRule="evenodd"></path>
            </svg>

            <div className="mt-4">
              <h2 className="text-2xl text-slate-700 dark:text-navy-100"> Delete </h2>
              <p className="mt-2"> Once you've deleted the record , the category ({category.name}) will no longer be available. This action cannot be undo. </p>
              <button
                onClick={onClose}
                className="btn mt-6 bg-info font-medium text-white hover:bg-info-focus focus:bg-info-focus active:bg-info-focus/90">
                Cancel
              </button>
              <button
                className="btn mt-6 bg-error font-medium text-white hover:bg-error-focus focus:bg-error-focus active:bg-error-focus/90"
                onClick={() => {
                  dispatch(categoryDeleteStart({
                    category_id: category.category_id,
                    skip: (12 * page) - 1,
                    take: 1,
                    ...filter,
                  }))
                  onClose();
                }}>
                Delete
              </button>
            </div>
          </div>
        );
      }
    })
  }

  return (
    profile.loading ?
      <PageLoader />
      : Object.keys(profile.data).length > 0 ?
        <>
          {/* HEADER */}
          <div className="flex items-center justify-between py-5 lg:py-6">
            <div className="flex items-center space-x-4">
              <h2 className="text-xl font-medium text-slate-800 dark:text-navy-50 lg:text-2xl"> Categories </h2>
              <div className="hidden h-full py-1 sm:flex">
                <div className="h-full w-px bg-slate-300 dark:bg-navy-600">&nbsp;</div>
              </div>
              <ul className="hidden flex-wrap items-center space-x-2 sm:flex">
                <li className="flex items-center space-x-2">
                  <Link
                    to="/categories"
                    className="text-primary transition-colors hover:text-primary-focus dark:text-accent-light dark:hover:text-accent">
                    Categories
                  </Link>
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7"></path>
                  </svg>
                </li>
                <li>View Categories </li>
              </ul>
            </div>
            <div>
              <div className="inline-space flex flex-wrap">
                <div id="bulkActionDropDown" className="inline-flex">
                  <button
                    className="btn popper-ref space-x-2 rounded-full bg-warning font-medium text-white hover:bg-warning-focus focus:bg-warning-focus active:bg-warning-focus/90"
                    onClick={() => setShowBulkAction(val => !val)}
                    onBlur={() => setTimeout(() => { setShowBulkAction(false) }, 200)}
                  >
                    <span>Bulk Action</span>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 transition-transform duration-200" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7"></path>
                    </svg>
                  </button>
                  <div className={`popper-root ${showBulkAction ? "show" : ""}`} data-popper-placement="bottom-start" style={{
                    position: "absolute",
                    margin: "0px",
                    right: "82px",
                    top: "124px"
                  }}>
                    <div className="popper-box rounded-md border border-slate-150 bg-white py-1.5 font-inter dark:border-navy-500 dark:bg-navy-700">
                      <ul>
                        <li>
                          <button
                            onClick={() => bulkAction(1)}
                            className="bulkAction flex h-8 w-full items-center px-3 pr-8 font-medium tracking-wide outline-none transition-all hover:bg-slate-100 hover:text-slate-800 focus:bg-slate-100 focus:text-slate-800 dark:hover:bg-navy-600 dark:hover:text-navy-100 dark:focus:bg-navy-600 dark:focus:text-navy-100">
                            Approve All
                          </button>
                        </li>
                        <li>
                          <button
                            onClick={() => bulkAction(2)}
                            data-action-text="Are you sure to decline these records ?."
                            className="bulkAction flex h-8 w-full items-center px-3 pr-8 font-medium tracking-wide outline-none transition-all hover:bg-slate-100 hover:text-slate-800 focus:bg-slate-100 focus:text-slate-800 dark:hover:bg-navy-600 dark:hover:text-navy-100 dark:focus:bg-navy-600 dark:focus:text-navy-100">
                            Decline All
                          </button>
                        </li>
                      </ul>
                      <div className="my-1 h-px bg-slate-150 dark:bg-navy-500"></div>
                      <ul>
                        <li>
                          <button
                            onClick={() => bulkAction(3)}
                            data-action-text="Are you sure to delete these records ?. This action cannot be undo."
                            className="bulkAction flex h-8 w-full items-center px-3 pr-8 font-medium tracking-wide outline-none transition-all hover:bg-slate-100 hover:text-slate-800 focus:bg-slate-100 focus:text-slate-800 dark:hover:bg-navy-600 dark:hover:text-navy-100 dark:focus:bg-navy-600 dark:focus:text-navy-100">
                            Delete All
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* FILTER */}
          <div className="grid grid-cols-1 gap-4 sm:gap-5 lg:gap-6">
            <div className="flex items-center justify-between">
              <Link
                to="/categories/create"
                className="btn space-x-2 bg-primary font-medium text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90"
              >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                  <path fillRule="evenodd" d="M3 6a3 3 0 013-3h2.25a3 3 0 013 3v2.25a3 3 0 01-3 3H6a3 3 0 01-3-3V6zm9.75 0a3 3 0 013-3H18a3 3 0 013 3v2.25a3 3 0 01-3 3h-2.25a3 3 0 01-3-3V6zM3 15.75a3 3 0 013-3h2.25a3 3 0 013 3V18a3 3 0 01-3 3H6a3 3 0 01-3-3v-2.25zm9.75 0a3 3 0 013-3H18a3 3 0 013 3V18a3 3 0 01-3 3h-2.25a3 3 0 01-3-3v-2.25z" clipRule="evenodd"></path>
                </svg>
                <span>Add Category</span>
              </Link>
              <Formik
                initialValues={{
                  search_key: searchParams.get("search_key") ? searchParams.get("search_key") : "",
                  status: searchParams.get("status") ? searchParams.get("status") : "",
                }}
                onSubmit={val => {
                  navigate(`/categories?search_key=${val.search_key}&status=${val.status}`)
                }}
              >
                {({ values, setFieldValue, resetForm }) =>
                  <Form className="form">
                    <div className="flex items-center gap-2 justify-between">
                      <div>
                        <label className="block">
                          <Field
                            className="form-input w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                            placeholder="Search by Name"
                            type="text"
                            name="search_key"
                          />
                        </label>
                      </div>
                      <div onClick={() => setFilterStatus(status => !status)} onBlur={() => setTimeout(() => { setFilterStatus(false) }, 200)}>
                        <div className="ts-wrapper w-16em single">
                          <div className="ts-control">
                            <input
                              type="select-one"
                              placeholder="Select Status"
                              readOnly={true}
                              value={values.status === "1" ? "Approved" : values.status === "0" ? "Declined" : ""}
                            />
                          </div>
                          <div className="ts-dropdown single" style={{ display: filterStatus ? "block" : "none", visibility: "visible" }}>
                            <div role="listbox" tabIndex="-1" className="ts-dropdown-content" id="tomOne-ts-dropdown">
                              <div data-selectable="" className={`option ${values.status === "" ? "active" : ""}`} onClick={() => setFieldValue("status", "")}> All </div>
                              <div data-selectable="" className={`option ${values.status === "1" ? "active" : ""}`} onClick={() => setFieldValue("status", "1")}> Approved </div>
                              <div data-selectable="" className={`option ${values.status === "0" ? "active" : ""}`} onClick={() => setFieldValue("status", "0")}> Declined </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <button type="submit"
                          className="btn h-9 w-9 rounded-full bg-success p-0 font-medium text-white hover:bg-success-focus hover:shadow-lg hover:shadow-success/50 focus:bg-success-focus active:bg-success-focus/90"
                          data-tooltip-id="tooltip-success"
                          data-tooltip-content="Search"
                          data-tooltip-place="top"
                        >
                          <i className="fa-solid fa-search"></i>
                        </button>
                        <button
                          type="button"
                          className="btn h-9 w-9 rounded-full bg-error p-0 font-medium text-white hover:bg-error-focus hover:shadow-lg hover:shadow-error/50 focus:bg-error-focus active:bg-error-focus/90"
                          onClick={() => {
                            setFieldValue("search_key", "");
                            setFieldValue("status", "");
                            navigate('/categories');
                          }}
                          data-tooltip-id="tooltip-error"
                          data-tooltip-content="Clear"
                          data-tooltip-place="top"
                        >
                          <i className="fa-solid fa-remove"></i>
                        </button>
                      </div>
                    </div>
                  </Form>
                }
              </Formik>
            </div >
          </div >
          {/* TABLE */}
          {
            categoryList.loading ?
              <PageLoader />
              : Object.keys(categoryList.data).length > 0 ?
                <div className='card mt-3'>
                  <div className="is-scrollbar-hidden min-w-full overflow-x-auto">
                    <table className="is-hoverable w-full text-left">
                      <thead>
                        <tr>
                          {categoryList.data.categories.length > 0 ?
                            <th className="whitespace-nowrap rounded-l-lg bg-slate-200 px-3 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                              <input
                                className="form-checkbox form-checkbox is-outline h-5 w-5 rounded bg-slate-100 border-slate-400/70 before:!bg-info checked:!border-info hover:!border-info focus:!border-info dark:bg-navy-900 dark:border-navy-500"
                                type="checkbox"
                                checked={selectedCategories.length === categoryList.data.categories.length}
                                onChange={() => setSelectedCategories(selectedCategories.length === categoryList.data.categories.length ?
                                  [] : categoryList.data.categories
                                )}
                              />
                            </th> : null
                          }
                          <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5"> # </th>
                          <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                            Picture
                          </th>
                          <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                            Name
                          </th>
                          <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                            SUB CATEGORIES
                          </th>
                          <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                            Status
                          </th>
                          <th className="whitespace-nowrap rounded-r-lg bg-slate-200 px-3 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                            Actions
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {categoryList.data.categories.length > 0 ?
                          categoryList.data.categories.map((category, i) =>
                            <tr className="border-y border-transparent border-b-slate-200 dark:border-b-navy-500" key={i}>
                              <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                                <label className="bulkModelId inline-flex items-center space-x-2">
                                  <input
                                    className="checkSingle form-checkbox form-checkbox is-outline h-5 w-5 rounded bg-slate-100 border-slate-400/70 before:!bg-info checked:!border-info hover:!border-info focus:!border-info dark:bg-navy-900 dark:border-navy-500"
                                    type="checkbox"
                                    data-model-id="156"
                                    checked={selectedCategories.includes(category)}
                                    onChange={() => setSelectedCategories(categories => categories.includes(category) ?
                                      categories.filter(cat => cat.category_id != category.category_id)
                                      : [...categories, category]
                                    )}
                                  />
                                </label>
                              </td>
                              <td className="whitespace-nowrap px-4 py-3 sm:px-5"> {(12 * (page - 1)) + (i + 1)} </td>
                              <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                                <div className="avatar flex h-10 w-10">
                                  <img className="mask is-squircle" src={category.picture} alt="avatar" />
                                </div>
                              </td>
                              <td className="whitespace-nowrap px-3 py-3 font-medium text-slate-700 dark:text-navy-100 lg:px-5">
                                <Link to={`/categories`} className="text-info">{category.name}</Link>
                              </td>
                              <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                                <Link to="/sub_categories" className="badge rounded-full border border-info text-info">
                                  {category.sub_categories_count}
                                </Link>
                              </td>
                              <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                                <label className="inline-flex items-center space-x-2">
                                  {category.status == 1 ?
                                    <div className="badge space-x-2.5 rounded-full bg-success/10 text-success dark:bg-success/15" id="statusBadge156">
                                      <div className="h-2 w-2 rounded-full bg-current"></div>
                                      <span id="statusText156">Approved</span>
                                    </div>
                                    : <div className="badge space-x-2.5 rounded-full bg-error/10 text-error dark:bg-error/15" id="statusBadge19">
                                      <div className="h-2 w-2 rounded-full bg-current"></div>
                                      <span id="statusText19">Declined</span>
                                    </div>
                                  }
                                  <input
                                    className="form-switch is-outline h-5 w-10 rounded-full border border-slate-400/70 bg-transparent before:rounded-full before:bg-slate-300 checked:!border-success checked:before:!bg-success dark:border-navy-400 dark:before:bg-navy-300"
                                    type="checkbox"
                                    checked={category.status == 1}
                                    onChange={() => dispatch(categoryStatusStart({ category_id: category.category_id }))}
                                    disabled={categoryStatus.buttonDisable}
                                  />
                                  {categoryStatus.buttonDisable && categoryStatus.data.category_id === category.category_id ?
                                    <ButtonLoader />
                                    : <div className="dummy-loading"></div>
                                  }
                                </label>
                              </td>
                              <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                                <label className="flex items-center space-x-2">
                                  <Link
                                    to={`/categories/show/${category.category_id}`}
                                    className="btn h-9 w-9 rounded-full bg-secondary p-0 font-medium text-white hover:bg-secondary-focus hover:shadow-lg hover:shadow-secondary/50 focus:bg-secondary-focus active:bg-secondary-focus/90"
                                    data-tooltip-id="tooltip-secondary"
                                    data-tooltip-content="View"
                                    data-tooltip-place="top"
                                  >
                                    <i className="fa-solid fa-eye"></i>
                                  </Link>
                                  <Link
                                    to={`/categories/edit/${category.category_id}`}
                                    className="btn h-9 w-9 rounded-full bg-info p-0 font-medium text-white hover:bg-info-focus hover:shadow-lg hover:shadow-info/50 focus:bg-info-focus active:bg-info-focus/90"
                                    data-tooltip-id="tooltip-info"
                                    data-tooltip-content="Edit"
                                    data-tooltip-place="top"
                                  >
                                    <i className="fa-solid fa-edit"></i>
                                  </Link>
                                  {categoryDelete.buttonDisable && categoryDelete.data.category_id === category.category_id ?
                                    <ButtonLoader varient="error" />
                                    : <button
                                      className="btn h-9 w-9 rounded-full bg-error p-0 font-medium text-white hover:bg-error-focus hover:shadow-lg hover:shadow-error/50 focus:bg-error-focus active:bg-error-focus/90"
                                      onClick={() => remove(category)}
                                      disabled={categoryDelete.buttonDisable}
                                      data-tooltip-id="tooltip-error"
                                      data-tooltip-content="Delete"
                                      data-tooltip-place="top"
                                    >
                                      <i className="fa-solid fa-trash"></i>
                                    </button>
                                  }
                                  <Link
                                    to={`/sub_categories?search_key=${category.name}`}
                                    className="btn h-9 w-9 rounded-full bg-success p-0 font-medium text-white hover:bg-success-focus hover:shadow-lg hover:shadow-success/50 focus:bg-success-focus active:bg-success-focus/90"
                                    data-tooltip-id="tooltip-success"
                                    data-tooltip-content="Sub Categories"
                                    data-tooltip-place="top"
                                  >
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                                      <path fillRule="evenodd" d="M3.5 2A1.5 1.5 0 002 3.5V15a3 3 0 106 0V3.5A1.5 1.5 0 006.5 2h-3zm11.753 6.99L9.5 14.743V6.257l1.51-1.51a1.5 1.5 0 012.122 0l2.121 2.121a1.5 1.5 0 010 2.122zM8.364 18H16.5a1.5 1.5 0 001.5-1.5v-3a1.5 1.5 0 00-1.5-1.5h-2.136l-6 6zM5 16a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd"></path>
                                    </svg>
                                  </Link>
                                </label>
                              </td>
                            </tr>
                          )
                          : <tr className="border-y border-transparent border-b-slate-200 dark:border-b-navy-500">
                            <td className="whitespace-nowrap px-3 py-3 font-medium text-slate-700 dark:text-navy-100 lg:px-5">
                              No Data Found
                            </td>
                          </tr>
                        }
                      </tbody>
                    </table>
                  </div>

                  <div className="flex flex-col justify-end space-y-4 px-4 py-4 sm:flex-row sm:items-center sm:space-y-0 sm:px-5">
                    {categoryList.data.categories.length > 0 ?
                      <ol className="pagination space-x-1.5">
                        <li>
                          <nav role="navigation" aria-label="Pagination Navigation" className="flex items-center justify-between">
                            <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
                              <div className='px-2'>
                                <p className="text-sm text-gray-700 leading-5">
                                  Showing
                                  <span className="font-medium"> {(12 * (page - 1)) + 1} </span>
                                  to
                                  <span className="font-medium"> {(12 * (page - 1)) + categoryList.data.categories.length} </span>
                                  of
                                  <span className="font-medium"> {categoryList.data.total_categories} </span>
                                  results
                                </p>
                              </div>
                              <Pagination
                                page={page}
                                pageCount={Math.ceil(categoryList.data.total_categories / 12)}
                                handlePageClick={handlePageClick}
                              />
                            </div>
                          </nav>
                        </li>
                      </ol>
                      : null
                    }
                  </div>
                </div>
                : <SomethingWentWrong buttonText="Retry" handleClick={() => { }} />
          }
        </>
        : <SomethingWentWrong buttonText="Retry" handleClick={() => dispatch(profileStart())} />
  );
}

export default CategoryIndex;