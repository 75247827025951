import React from 'react';
import { t } from 'react-multi-lang';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

const AdminSidebar = () => {
  const location = useLocation();

  const profile = useSelector((state) => state.admin.profile);

  return (
    <div className="sidebar print:hidden">
      {/* Main Sidebar */}
      <div className="main-sidebar">
        <div
          className="flex h-full w-full flex-col items-center border-r border-slate-150 bg-white dark:border-navy-700 dark:bg-navy-800"
        >
          {/* Application Logo */}
          <div className="flex pt-4">
            <a href="/">
              <img
                className="h-11 w-11 transition-transform duration-500 ease-in-out hover:rotate-[360deg]"
                src={window.location.origin + "/images/app-logo.svg"}
                alt="logo"
              />
            </a>
          </div>

          {/* Main Sections Links */}
          <div
            className="is-scrollbar-hidden flex grow flex-col space-y-4 overflow-y-auto pt-6"
          >
            {/* Dashobards */}
            <Link
              to="/"
              className={`tooltip-main-sidebar flex h-11 w-11 items-center justify-center rounded-lg outline-none transition-colors duration-200 hover:bg-primary/20 focus:bg-primary/20 active:bg-primary/25 dark:hover:bg-navy-450 dark:focus:bg-navy-450 dark:active:bg-navy-450/90
              ${location.pathname === "/" ? "bg-primary/10 text-primary dark:bg-navy-600 dark:text-accent-light" : ""}
              `}
            >
              <svg
                className="h-7 w-7"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  fill="currentColor"
                  fillOpacity=".3"
                  d="M5 14.059c0-1.01 0-1.514.222-1.945.221-.43.632-.724 1.453-1.31l4.163-2.974c.56-.4.842-.601 1.162-.601.32 0 .601.2 1.162.601l4.163 2.974c.821.586 1.232.88 1.453 1.31.222.43.222.935.222 1.945V19c0 .943 0 1.414-.293 1.707C18.414 21 17.943 21 17 21H7c-.943 0-1.414 0-1.707-.293C5 20.414 5 19.943 5 19v-4.94Z"
                />
                <path
                  fill="currentColor"
                  d="M3 12.387c0 .267 0 .4.084.441.084.041.19-.04.4-.204l7.288-5.669c.59-.459.885-.688 1.228-.688.343 0 .638.23 1.228.688l7.288 5.669c.21.163.316.245.4.204.084-.04.084-.174.084-.441v-.409c0-.48 0-.72-.102-.928-.101-.208-.291-.355-.67-.65l-7-5.445c-.59-.459-.885-.688-1.228-.688-.343 0-.638.23-1.228.688l-7 5.445c-.379.295-.569.442-.67.65-.102.208-.102.448-.102.928v.409Z"
                />
                <path
                  fill="currentColor"
                  d="M11.5 15.5h1A1.5 1.5 0 0 1 14 17v3.5h-4V17a1.5 1.5 0 0 1 1.5-1.5Z"
                />
                <path
                  fill="currentColor"
                  d="M17.5 5h-1a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5Z"
                />
              </svg>
            </Link>

            {profile.data.kyc_status == 3 && (
              <Link
                to="/payment"
                className={`tooltip-main-sidebar flex h-11 w-11 items-center justify-center rounded-lg outline-none transition-colors duration-200 hover:bg-primary/20 focus:bg-primary/20 active:bg-primary/25 dark:hover:bg-navy-450 dark:focus:bg-navy-450 dark:active:bg-navy-450/90
                ${location.pathname === "/payment" ? "bg-primary/10 text-primary dark:bg-navy-600 dark:text-accent-light" : ""}
                `}
              >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                  <path d="M10.464 8.746c.227-.18.497-.311.786-.394v2.795a2.252 2.252 0 01-.786-.393c-.394-.313-.546-.681-.546-1.004 0-.323.152-.691.546-1.004zM12.75 15.662v-2.824c.347.085.664.228.921.421.427.32.579.686.579.991 0 .305-.152.671-.579.991a2.534 2.534 0 01-.921.42z"></path>
                  <path fillRule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zM12.75 6a.75.75 0 00-1.5 0v.816a3.836 3.836 0 00-1.72.756c-.712.566-1.112 1.35-1.112 2.178 0 .829.4 1.612 1.113 2.178.502.4 1.102.647 1.719.756v2.978a2.536 2.536 0 01-.921-.421l-.879-.66a.75.75 0 00-.9 1.2l.879.66c.533.4 1.169.645 1.821.75V18a.75.75 0 001.5 0v-.81a4.124 4.124 0 001.821-.749c.745-.559 1.179-1.344 1.179-2.191 0-.847-.434-1.632-1.179-2.191a4.122 4.122 0 00-1.821-.75V8.354c.29.082.559.213.786.393l.415.33a.75.75 0 00.933-1.175l-.415-.33a3.836 3.836 0 00-1.719-.755V6z" clipRule="evenodd"></path>
                </svg>
              </Link>
            )}

            {/* Subscriptions */}
            {/* <Link
              to="/subscriptions"
              className={`tooltip-main-sidebar flex h-11 w-11 items-center justify-center rounded-lg outline-none transition-colors duration-200 hover:bg-primary/20 focus:bg-primary/20 active:bg-primary/25 dark:hover:bg-navy-450 dark:focus:bg-navy-450 dark:active:bg-navy-450/90
              ${location.pathname === "/subscriptions" ? "bg-primary/10 text-primary dark:bg-navy-600 dark:text-accent-light" : ""}
              `}
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                <path d="M10.464 8.746c.227-.18.497-.311.786-.394v2.795a2.252 2.252 0 01-.786-.393c-.394-.313-.546-.681-.546-1.004 0-.323.152-.691.546-1.004zM12.75 15.662v-2.824c.347.085.664.228.921.421.427.32.579.686.579.991 0 .305-.152.671-.579.991a2.534 2.534 0 01-.921.42z"></path>
                <path fillRule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zM12.75 6a.75.75 0 00-1.5 0v.816a3.836 3.836 0 00-1.72.756c-.712.566-1.112 1.35-1.112 2.178 0 .829.4 1.612 1.113 2.178.502.4 1.102.647 1.719.756v2.978a2.536 2.536 0 01-.921-.421l-.879-.66a.75.75 0 00-.9 1.2l.879.66c.533.4 1.169.645 1.821.75V18a.75.75 0 001.5 0v-.81a4.124 4.124 0 001.821-.749c.745-.559 1.179-1.344 1.179-2.191 0-.847-.434-1.632-1.179-2.191a4.122 4.122 0 00-1.821-.75V8.354c.29.082.559.213.786.393l.415.33a.75.75 0 00.933-1.175l-.415-.33a3.836 3.836 0 00-1.719-.755V6z" clipRule="evenodd"></path>
              </svg>
            </Link> */}

            {/* Chats */}
            {/* <Link
              to="/chats"
              className={`tooltip-main-sidebar flex h-11 w-11 items-center justify-center rounded-lg outline-none transition-colors duration-200 hover:bg-primary/20 focus:bg-primary/20 active:bg-primary/25 dark:hover:bg-navy-450 dark:focus:bg-navy-450 dark:active:bg-navy-450/90
              ${location.pathname === "/chats" ? "bg-primary/10 text-primary dark:bg-navy-600 dark:text-accent-light" : ""}
              `}
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                <path d="M4.913 2.658c2.075-.27 4.19-.408 6.337-.408 2.147 0 4.262.139 6.337.408 1.922.25 3.291 1.861 3.405 3.727a4.403 4.403 0 00-1.032-.211 50.89 50.89 0 00-8.42 0c-2.358.196-4.04 2.19-4.04 4.434v4.286a4.47 4.47 0 002.433 3.984L7.28 21.53A.75.75 0 016 21v-4.03a48.527 48.527 0 01-1.087-.128C2.905 16.58 1.5 14.833 1.5 12.862V6.638c0-1.97 1.405-3.718 3.413-3.979z"></path>
                <path d="M15.75 7.5c-1.376 0-2.739.057-4.086.169C10.124 7.797 9 9.103 9 10.609v4.285c0 1.507 1.128 2.814 2.67 2.94 1.243.102 2.5.157 3.768.165l2.782 2.781a.75.75 0 001.28-.53v-2.39l.33-.026c1.542-.125 2.67-1.433 2.67-2.94v-4.286c0-1.505-1.125-2.811-2.664-2.94A49.392 49.392 0 0015.75 7.5z"></path>
              </svg>
            </Link> */}

            {/* Categories */}
            {/* <Link
              to="/categories"
              className={`tooltip-main-sidebar flex h-11 w-11 items-center justify-center rounded-lg outline-none transition-colors duration-200 hover:bg-primary/20 focus:bg-primary/20 active:bg-primary/25 dark:hover:bg-navy-450 dark:focus:bg-navy-450 dark:active:bg-navy-450/90
              ${location.pathname === "/categories" ? "bg-primary/10 text-primary dark:bg-navy-600 dark:text-accent-light" : ""}
              `}
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                <path fillRule="evenodd" d="M3 6a3 3 0 013-3h2.25a3 3 0 013 3v2.25a3 3 0 01-3 3H6a3 3 0 01-3-3V6zm9.75 0a3 3 0 013-3H18a3 3 0 013 3v2.25a3 3 0 01-3 3h-2.25a3 3 0 01-3-3V6zM3 15.75a3 3 0 013-3h2.25a3 3 0 013 3V18a3 3 0 01-3 3H6a3 3 0 01-3-3v-2.25zm9.75 0a3 3 0 013-3H18a3 3 0 013 3V18a3 3 0 01-3 3h-2.25a3 3 0 01-3-3v-2.25z" clipRule="evenodd"></path>
              </svg>
            </Link> */}

          </div>

          {/* Bottom Links */}
          <div className="flex flex-col items-center space-y-3 py-3">
            {/* Settings */}
            {/* <a
              href="forms-layout-5.html"
              className="flex h-11 w-11 items-center justify-center rounded-lg outline-none transition-colors duration-200 hover:bg-primary/20 focus:bg-primary/20 active:bg-primary/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25"
            >
              <svg
                className="h-7 w-7"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillOpacity="0.3"
                  fill="currentColor"
                  d="M2 12.947v-1.771c0-1.047.85-1.913 1.899-1.913 1.81 0 2.549-1.288 1.64-2.868a1.919 1.919 0 0 1 .699-2.607l1.729-.996c.79-.474 1.81-.192 2.279.603l.11.192c.9 1.58 2.379 1.58 3.288 0l.11-.192c.47-.795 1.49-1.077 2.279-.603l1.73.996a1.92 1.92 0 0 1 .699 2.607c-.91 1.58-.17 2.868 1.639 2.868 1.04 0 1.899.856 1.899 1.912v1.772c0 1.047-.85 1.912-1.9 1.912-1.808 0-2.548 1.288-1.638 2.869.52.915.21 2.083-.7 2.606l-1.729.997c-.79.473-1.81.191-2.279-.604l-.11-.191c-.9-1.58-2.379-1.58-3.288 0l-.11.19c-.47.796-1.49 1.078-2.279.605l-1.73-.997a1.919 1.919 0 0 1-.699-2.606c.91-1.58.17-2.869-1.639-2.869A1.911 1.911 0 0 1 2 12.947Z"
                />
                <path
                  fill="currentColor"
                  d="M11.995 15.332c1.794 0 3.248-1.464 3.248-3.27 0-1.807-1.454-3.272-3.248-3.272-1.794 0-3.248 1.465-3.248 3.271 0 1.807 1.454 3.271 3.248 3.271Z"
                />
              </svg>
            </a> */}
          </div>
        </div>
      </div>

      {/* Sidebar Panel */}
      <div className="sidebar-panel">
        <div
          className="flex h-full grow flex-col bg-white pl-[var(--main-sidebar-width)] dark:bg-navy-750"
        >
          {/* Sidebar Panel Header */}
          <div
            className="flex h-18 w-full items-center justify-between pl-4 pr-1"
          >
            <p
              className="text-base tracking-wider text-slate-800 dark:text-navy-100"
            >
              Efimarkets
            </p>
            <button
              className="sidebar-close btn h-7 w-7 rounded-full p-0 text-primary hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:text-accent-light/80 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25 xl:hidden"
              onClick={() => { document.body.classList.toggle("is-sidebar-open"); }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M15 19l-7-7 7-7"
                />
              </svg>
            </button>
          </div>

          {/* Sidebar Panel Body */}
          <div
            className="nav-wrapper h-[calc(100%-4.5rem)] overflow-x-hidden pb-6"
            data-simplebar
          >
            <ul className="flex flex-1 flex-col px-4 font-inter">
              <li>
                <Link
                  to="/"
                  className={`nav-link flex py-2 text-xs+ tracking-wide outline-none transition-colors duration-300 ease-in-out side-link
                  ${location.pathname === "/" ? " ont-medium text-primary dark:text-accent-light" : ""}
                  `}
                >
                  Dashboard
                </Link>
              </li>
              {profile.data.kyc_status == 3 && (
                <li>
                  <Link
                    to="/payment"
                    className={`nav-link flex py-2 text-xs+ tracking-wide outline-none transition-colors duration-300 ease-in-out side-link
                    ${location.pathname === "/payment" ? " ont-medium text-primary dark:text-accent-light" : ""}
                    `}
                  >
                    Payment
                  </Link>
                </li>
            )}
              {/* <li>
                <Link
                  to="/subscriptions"
                  className={`nav-link flex py-2 text-xs+ tracking-wide outline-none transition-colors duration-300 ease-in-out side-link
                  ${location.pathname === "/subscriptions" ? " ont-medium text-primary dark:text-accent-light" : ""}
                  `}
                >
                  Subscriptions
                </Link>
              </li>
              <li>
                <Link
                  to="/chats"
                  className={`nav-link flex py-2 text-xs+ tracking-wide outline-none transition-colors duration-300 ease-in-out side-link
                  ${location.pathname === "/chats" ? " ont-medium text-primary dark:text-accent-light" : ""}
                  `}
                >
                  Chats
                </Link>
              </li> */}
            </ul>
            {/* <div className="my-3 mx-4 h-px bg-slate-200 dark:bg-navy-500"></div> */}
            {/* <ul className="flex flex-1 flex-col px-4 font-inter">
              <li
                className="ac nav-parent [&.is-active_svg]:rotate-90 [&.is-active_.ac-trigger]:font-semibold [&.is-active_.ac-trigger]:text-slate-800 dark:[&.is-active_.ac-trigger]:text-navy-50"
              >
                <button
                  className="ac-trigger flex w-full items-center justify-between py-2 text-xs+ tracking-wide text-slate-600 outline-none transition-[color,padding-left] duration-300 ease-in-out hover:text-slate-800 dark:text-navy-200 dark:hover:text-navy-50"
                >
                  <span>Cryptocurrency</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4 text-slate-400 transition-transform ease-in-out"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M9 5l7 7-7 7"
                    ></path>
                  </svg>
                </button>
                <ul className="ac-panel">
                  <li>
                    <a
                      href="dashboards-crypto-1.html"
                      className="nav-link flex items-center justify-between p-2 text-xs+ tracking-wide outline-none transition-[color,padding-left] duration-300 ease-in-out hover:pl-4"
                      data-default-className="text-slate-600 hover:text-slate-800 dark:text-navy-200 dark:hover:text-navy-50"
                      data-active-className="font-medium text-primary dark:text-accent-light"
                    >
                      <div className="flex items-center space-x-2">
                        <div
                          className="h-1.5 w-1.5 rounded-full border border-current opacity-40"
                        ></div>
                        <span>Cryptocurrency v1</span>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a
                      href="dashboards-crypto-2.html"
                      className="nav-link flex items-center justify-between p-2 text-xs+ tracking-wide outline-none transition-[color,padding-left] duration-300 ease-in-out hover:pl-4"
                      data-default-className="text-slate-600 hover:text-slate-800 dark:text-navy-200 dark:hover:text-navy-50"
                      data-active-className="font-medium text-primary dark:text-accent-light"
                    >
                      <div className="flex items-center space-x-2">
                        <div
                          className="h-1.5 w-1.5 rounded-full border border-current opacity-40"
                        ></div>
                        <span>Cryptocurrency v2</span>
                      </div>
                    </a>
                  </li>
                </ul>
              </li>
              <li
                className="ac nav-parent [&.is-active_svg]:rotate-90 [&.is-active_.ac-trigger]:font-semibold [&.is-active_.ac-trigger]:text-slate-800 dark:[&.is-active_.ac-trigger]:text-navy-50"
              >
                <button
                  className="ac-trigger flex w-full items-center justify-between py-2 text-xs+ tracking-wide text-slate-600 outline-none transition-[color,padding-left] duration-300 ease-in-out hover:text-slate-800 dark:text-navy-200 dark:hover:text-navy-50"
                >
                  <span>Banking</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4 text-slate-400 transition-transform ease-in-out"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M9 5l7 7-7 7"
                    ></path>
                  </svg>
                </button>
                <ul className="ac-panel">
                  <li>
                    <a
                      href="dashboards-banking-1.html"
                      className="nav-link flex items-center justify-between p-2 text-xs+ tracking-wide outline-none transition-[color,padding-left] duration-300 ease-in-out hover:pl-4"
                      data-default-className="text-slate-600 hover:text-slate-800 dark:text-navy-200 dark:hover:text-navy-50"
                      data-active-className="font-medium text-primary dark:text-accent-light"
                    >
                      <div className="flex items-center space-x-2">
                        <div
                          className="h-1.5 w-1.5 rounded-full border border-current opacity-40"
                        ></div>
                        <span>Banking v1</span>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a
                      href="dashboards-banking-2.html"
                      className="nav-link flex items-center justify-between p-2 text-xs+ tracking-wide outline-none transition-[color,padding-left] duration-300 ease-in-out hover:pl-4"
                      data-default-className="text-slate-600 hover:text-slate-800 dark:text-navy-200 dark:hover:text-navy-50"
                      data-active-className="font-medium text-primary dark:text-accent-light"
                    >
                      <div className="flex items-center space-x-2">
                        <div
                          className="h-1.5 w-1.5 rounded-full border border-current opacity-40"
                        ></div>
                        <span>Banking v2</span>
                      </div>
                    </a>
                  </li>
                </ul>
              </li>

              <li>
                <a
                  href="dashboards-personal.html"
                  data-default-className="text-slate-600 hover:text-slate-800 dark:text-navy-200 dark:hover:text-navy-50"
                  data-active-className="font-medium text-primary dark:text-accent-light"
                  className="nav-link flex py-2 text-xs+ tracking-wide outline-none transition-colors duration-300 ease-in-out"
                >
                  Personal
                </a>
              </li>
              <li>
                <a
                  href="dashboards-cms-analytics.html"
                  data-default-className="text-slate-600 hover:text-slate-800 dark:text-navy-200 dark:hover:text-navy-50"
                  data-active-className="font-medium text-primary dark:text-accent-light"
                  className="nav-link flex py-2 text-xs+ tracking-wide outline-none transition-colors duration-300 ease-in-out"
                >
                  CMS Analytics
                </a>
              </li>
              <li>
                <a
                  href="dashboards-influencer.html"
                  data-default-className="text-slate-600 hover:text-slate-800 dark:text-navy-200 dark:hover:text-navy-50"
                  data-active-className="font-medium text-primary dark:text-accent-light"
                  className="nav-link flex py-2 text-xs+ tracking-wide outline-none transition-colors duration-300 ease-in-out"
                >
                  Influencer
                </a>
              </li>
              <li>
                <a
                  href="dashboards-travel.html"
                  data-default-className="text-slate-600 hover:text-slate-800 dark:text-navy-200 dark:hover:text-navy-50"
                  data-active-className="font-medium text-primary dark:text-accent-light"
                  className="nav-link flex py-2 text-xs+ tracking-wide outline-none transition-colors duration-300 ease-in-out"
                >
                  Travel
                </a>
              </li>
              <li>
                <a
                  href="dashboards-teacher.html"
                  data-default-className="text-slate-600 hover:text-slate-800 dark:text-navy-200 dark:hover:text-navy-50"
                  data-active-className="font-medium text-primary dark:text-accent-light"
                  className="nav-link flex py-2 text-xs+ tracking-wide outline-none transition-colors duration-300 ease-in-out"
                >
                  Teacher
                </a>
              </li>
              <li>
                <a
                  href="dashboards-education.html"
                  data-default-className="text-slate-600 hover:text-slate-800 dark:text-navy-200 dark:hover:text-navy-50"
                  data-active-className="font-medium text-primary dark:text-accent-light"
                  className="nav-link flex py-2 text-xs+ tracking-wide outline-none transition-colors duration-300 ease-in-out"
                >
                  Education
                </a>
              </li>
              <li>
                <a
                  href="dashboards-authors.html"
                  data-default-className="text-slate-600 hover:text-slate-800 dark:text-navy-200 dark:hover:text-navy-50"
                  data-active-className="font-medium text-primary dark:text-accent-light"
                  className="nav-link flex py-2 text-xs+ tracking-wide outline-none transition-colors duration-300 ease-in-out"
                >
                  Authors
                </a>
              </li>
              <li>
                <a
                  href="dashboards-doctor.html"
                  data-default-className="text-slate-600 hover:text-slate-800 dark:text-navy-200 dark:hover:text-navy-50"
                  data-active-className="font-medium text-primary dark:text-accent-light"
                  className="nav-link flex py-2 text-xs+ tracking-wide outline-none transition-colors duration-300 ease-in-out"
                >
                  Doctor
                </a>
              </li>
              <li>
                <a
                  href="dashboards-employees.html"
                  data-default-className="text-slate-600 hover:text-slate-800 dark:text-navy-200 dark:hover:text-navy-50"
                  data-active-className="font-medium text-primary dark:text-accent-light"
                  className="nav-link flex py-2 text-xs+ tracking-wide outline-none transition-colors duration-300 ease-in-out"
                >
                  Employees
                </a>
              </li>
              <li>
                <a
                  href="dashboards-workspaces.html"
                  data-default-className="text-slate-600 hover:text-slate-800 dark:text-navy-200 dark:hover:text-navy-50"
                  data-active-className="font-medium text-primary dark:text-accent-light"
                  className="nav-link flex py-2 text-xs+ tracking-wide outline-none transition-colors duration-300 ease-in-out"
                >
                  Workspaces
                </a>
              </li>
              <li>
                <a
                  href="dashboards-meetings.html"
                  data-default-className="text-slate-600 hover:text-slate-800 dark:text-navy-200 dark:hover:text-navy-50"
                  data-active-className="font-medium text-primary dark:text-accent-light"
                  className="nav-link flex py-2 text-xs+ tracking-wide outline-none transition-colors duration-300 ease-in-out"
                >
                  Meetings
                </a>
              </li>
              <li>
                <a
                  href="dashboards-project-boards.html"
                  data-default-className="text-slate-600 hover:text-slate-800 dark:text-navy-200 dark:hover:text-navy-50"
                  data-active-className="font-medium text-primary dark:text-accent-light"
                  className="nav-link flex py-2 text-xs+ tracking-wide outline-none transition-colors duration-300 ease-in-out"
                >
                  Project Boards
                </a>
              </li>
            </ul>
            <div className="my-3 mx-4 h-px bg-slate-200 dark:bg-navy-500"></div>
            <ul className="flex flex-1 flex-col px-4 font-inter">
              <li>
                <a
                  href="dashboards-widget-ui.html"
                  data-default-className="text-slate-600 hover:text-slate-800 dark:text-navy-200 dark:hover:text-navy-50"
                  data-active-className="font-medium text-primary dark:text-accent-light"
                  className="nav-link flex py-2 text-xs+ tracking-wide outline-none transition-colors duration-300 ease-in-out"
                >
                  Widget UI
                </a>
              </li>
              <li>
                <a
                  href="dashboards-widget-contacts.html"
                  data-default-className="text-slate-600 hover:text-slate-800 dark:text-navy-200 dark:hover:text-navy-50"
                  data-active-className="font-medium text-primary dark:text-accent-light"
                  className="nav-link flex py-2 text-xs+ tracking-wide outline-none transition-colors duration-300 ease-in-out"
                >
                  Widget Contacts
                </a>
              </li>
            </ul> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminSidebar;