import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from "yup";
import { ButtonLoader } from '../Helper/Loader';
import { viewAPIKeyStart, generateAPIKeyStart, updateProfileStart, viewAPIKeyFailure } from '../../store/slices/AdminSlice';
import { getSuccessNotificationMessage } from '../Helper/NotificationMessage';
import PhoneInput, {
  formatPhoneNumberIntl,
  isValidPhoneNumber,
  isPossiblePhoneNumber,
} from "react-phone-number-input";
import { allCountries } from "country-telephone-data";

const BasicProfileTab = ({ profile, activeTab }) => {
  const dispatch = useDispatch();
  const generateAPIKey = useSelector(state => state.admin.generateAPIKey);
  const viewAPIKey = useSelector(state => state.admin.viewAPIKey);
  const updateProfile = useSelector(state => state.admin.updateProfile);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [password, setPassword] = useState("");
  const [image, setImage] = useState("");
  const [previewImage, setPreviewImage] = useState(profile.picture);
  const formRef = useRef();

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name required").test('len', 'Should not exceed 255 characters', val => val && val.toString().length < 255),
    email: Yup.string().email("Invalid Email").required("Email Required"),
    about: Yup.string().test('len', 'Should not exceed 255 characters', val => !val ? true : val.toString().length < 255),
    residence: Yup.string().required("Residence required"),
    citizenship: Yup.string().required("Citizenship required"),
    mobile: Yup.string()
      .test("valid-mobile", ("Invalid number"), (value) => {
        if (!value) {
          return false;
        }
        return isValidPhoneNumber(value);
      })
      .required(("Mobile required"))
      .matches(/^(?=.*[0-9])/, ("Invalid number")),
  });

  useEffect(() => {
    if (activeTab !== "profile") reset();
  }, [activeTab]);

  useEffect(() => {
    let objectUrl;
    if (image) {
      objectUrl = URL.createObjectURL(image);
      setPreviewImage(objectUrl);
    }
    return () => URL.revokeObjectURL(objectUrl)
  }, [image]);

  const getAPIKey = () => dispatch(profile.api_key_generated ? viewAPIKeyStart({ password }) : generateAPIKeyStart({ password }));

  const reset = () => {
    if (formRef.current)
      formRef.current.resetForm();
    setImage("");
    setPreviewImage(profile.picture);
    setShowConfirmation(false);
    dispatch(viewAPIKeyFailure());
  }

  const validateMobileNumber = (value) => {
    if (value) {
      if (isPossiblePhoneNumber(value) == false) {
        formRef.current.setFieldError("mobile","Invalid");
        return false;
      } else if (isValidPhoneNumber(value) == false) {
        formRef.current.setFieldError("mobile", "Invalid");
        return false;
      } else {
        return true;
      }
    } else {
      formRef.current.setFieldError("mobile", "Required");
      return false;
    }
  };

  const handleSubmit = values => {
    if (image) values = { ...values, picture: image };
    if (validateMobileNumber(values.mobile)) {
      const intlNo = formatPhoneNumberIntl(values.mobile);
      const countryCode = intlNo.substring(
        intlNo.indexOf("+") + 1,
        intlNo.indexOf(" ")
      );
      const mobile = intlNo
        .substring(intlNo.indexOf(" "), intlNo.length)
        .replaceAll(" ", "");
      values = {...values, mobile: mobile,country_code: countryCode}
    }
    dispatch(updateProfileStart(values));
  }

  function getCountryCode(mobileCode) {
    const countryData = allCountries.find(country => country.dialCode === mobileCode);
    return countryData ? countryData.iso2.toUpperCase() : null;
  }

  return (
    <>
      <div>
        <div className="grid grid-cols-12 gap-4 sm:gap-5 lg:gap-6">
          <div className="col-span-12 lg:col-span-12">
            <Formik
              initialValues={{
                name: profile.name,
                email: profile.email,
                about: profile.about,
                mobile: profile.mobile,
                country_code: profile.country_code,
                citizenship: profile.citizenship,
                residence: profile.residence,
              }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
              innerRef={formRef}
            >
              {({ values, setFieldValue, touched, errors }) =>
                <Form className="form">
                  <div className="">
                    <div className="flex flex-col">
                      <div className="p-4 sm:p-5">
                        <div className="space-y-5">
                          <div className="flex flex-row gap-6">
                            <div className="w-1/2">
                              <div>
                                <label className="block">
                                  <span className="font-medium text-slate-600 dark:text-navy-100">Name *</span>
                                  <Field
                                    className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                    placeholder="Enter Name"
                                    name="name"
                                  />
                                </label>
                                <div className="flex justify-between">
                                  <ErrorMessage
                                    component={"div"}
                                    name="name"
                                    className="errorMsg w-100 text-tiny+"
                                  />
                                </div>
                              </div>
                              <div className="mt-3">
                                <label className="block">
                                  <span className="font-medium text-slate-600 dark:text-navy-100">Residence *</span>
                                  <Field
                                    className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                    placeholder="Enter residence"
                                    name="residence"
                                  />
                                </label>
                                <div className="flex justify-between">
                                  <ErrorMessage
                                    component={"div"}
                                    name="residence"
                                    className="errorMsg w-100 text-tiny+"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="w-1/2">
                              <div>
                                <label className="block">
                                  <span className="font-medium text-slate-600 dark:text-navy-100">Email *</span>
                                  <Field
                                    className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                    placeholder="Enter Email"
                                    name="email"
                                  />
                                </label>
                                <span className="text-tiny+ text-slate-400 dark:text-navy-300 flex justify-between">
                                  <ErrorMessage
                                    component={"div"}
                                    name="email"
                                    className="errorMsg w-100"
                                  />
                                </span>
                              </div>
                              <div className="mt-3">
                                <label className="block">
                                  <span className="font-medium text-slate-600 dark:text-navy-100">Citizenship *</span>
                                  <Field
                                    className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                    placeholder="Enter citizenship"
                                    name="citizenship"
                                  />
                                </label>
                                <div className="flex justify-between">
                                  <ErrorMessage
                                    component={"div"}
                                    name="citizenship"
                                    className="errorMsg w-100 text-tiny+"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="flex flex-row gap-6">
                            <div className="w-1/2">
                              <div className="block">
                                <span className="font-medium text-slate-600 dark:text-navy-100"> Picture</span><br />
                                <div>
                                  <label className="btn relative bg-primary font-medium text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90">
                                    <input
                                      tabIndex="-1"
                                      type="file"
                                      className="pointer-events-none absolute inset-0 h-full w-full opacity-0"
                                      accept=".png, .jpg, .jpeg"
                                      onChange={e => setImage(e.target.files[0])}
                                    />
                                    <div className="flex items-center space-x-2">
                                      <i className="fa-solid fa-cloud-arrow-up text-base"></i>
                                      <span>Choose File</span>
                                    </div>
                                  </label>
                                </div>
                                <div className="my-2 avatar h-24 w-24">
                                  <img
                                    className="rounded-lg"
                                    src={previewImage}
                                    alt="avatar"
                                  />
                                </div>
                                <div>
                                </div>
                              </div>
                            </div>
                            {/* <div className="w-1/2">
                              <div className="block">
                                <span className="font-medium text-slate-600 dark:text-navy-100"> API Key</span><br />
                                <div>
                                  {Object.keys(viewAPIKey.data).length === 0 ?
                                    !showConfirmation ?
                                      <label className="btn relative bg-primary font-medium text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90">
                                        <button
                                          type="button"
                                          className="flex items-center space-x-2"
                                          onClick={() => setShowConfirmation(true)}>
                                          {profile.api_key_generated ?
                                            <>
                                              <i className="fa-solid fa-eye text-base"></i>
                                              <span>Show Key</span>
                                            </>
                                            : <span>Generate Key</span>
                                          }
                                        </button>
                                      </label>
                                      : <div className="mt-0 space-y-4">
                                        <label className="block">
                                          <input
                                            className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                            placeholder="Enter password"
                                            type="password"
                                            value={password}
                                            onChange={e => setPassword(e.target.value)}
                                            autoComplete="new-password"
                                            onKeyDown={e => {
                                              if (e.key === 'Enter') {
                                                e.preventDefault();
                                                getAPIKey();
                                              }
                                            }}
                                          />
                                        </label>
                                        <div className="space-x-2 text-right">
                                          <button
                                            type="button"
                                            className="btn min-w-[7rem] rounded-full border border-slate-300 font-medium text-slate-800 hover:bg-slate-150 focus:bg-slate-150 active:bg-slate-150/80 dark:border-navy-450 dark:text-navy-50 dark:hover:bg-navy-500 dark:focus:bg-navy-500 dark:active:bg-navy-500/90"
                                            onClick={() => setShowConfirmation(false)}
                                          >
                                            Cancel
                                          </button>
                                          <button
                                            type="button"
                                            className="btn min-w-[7rem] rounded-full bg-primary font-medium text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90"
                                            onClick={() => getAPIKey()}
                                          >
                                            {generateAPIKey.buttonDisable || viewAPIKey.buttonDisable ?
                                              <ButtonLoader varient="info" size={5} />
                                              : "Submit"
                                            }
                                          </button>
                                        </div>
                                      </div>
                                    : <div className="mt-0 space-y-4">
                                      <div className="flex -space-x-px">
                                        <input
                                          className="form-input w-full border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:z-10 hover:border-slate-400 focus:z-10 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                          type="text"
                                          value={viewAPIKey.data.api_key}
                                          readOnly={true}
                                          onKeyDown={e => { if (e.key === 'Enter') e.preventDefault(); }}
                                        />
                                        <button
                                          type="button"
                                          class="btn rounded-l-none bg-slate-150 font-medium text-primary hover:bg-slate-200 focus:bg-slate-200 active:bg-slate-200/80 dark:bg-navy-500 dark:text-navy-50 dark:hover:bg-navy-450 dark:focus:bg-navy-450 dark:active:bg-navy-450/90"
                                          onClick={() => {
                                            navigator.clipboard.writeText(viewAPIKey.data.api_key);
                                            getSuccessNotificationMessage("API Key copied")
                                          }}
                                        >
                                          <i className="fa-solid fa-clipboard text-base"></i>
                                        </button>
                                      </div>
                                    </div>
                                  }
                                </div>
                              </div>
                            </div> */}
                            <div className="w-1/2">
                              <div className='profile-tab'>
                                <label className="block" htmlFor='mobile'>
                                  <span className="font-medium text-slate-600 dark:text-navy-100">Mobile Number *</span>
                                  <PhoneInput
                                    name="mobile"
                                    defaultCountry={getCountryCode(values.country_code)}
                                    placeholder="Mobile number"
                                    useNationalFormatForDefaultCountryValue={true}
                                    value={formatPhoneNumberIntl("+"+values.country_code+values.mobile)}
                                    onChange={(value) =>
                                      setFieldValue("mobile", value)
                                    }
                                    onBlur={() => formRef.current.submitForm()}
                                    international
                                    className={`form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent ${
                                      touched.mobile && errors.mobile
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                  />
                                  <span className="text-tiny+ text-slate-400 dark:text-navy-300 flex justify-between">
                                    <ErrorMessage
                                      component={"div"}
                                      name="mobile"
                                      className="errorMsg w-100"
                                    />
                                 </span>
                                </label>
                              </div>
                            </div>
                          </div>
                          <div>
                            <label className="block">
                              <span>About</span>
                              <textarea
                                rows="4"
                                placeholder="Enter About"
                                className="form-textarea mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent p-2.5 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                onChange={e => setFieldValue("about", e.target.value)}
                                value={values.about}
                              />
                            </label>
                            <div className="flex justify-between">
                              <span className="text-tiny+ text-slate-400 dark:text-navy-300">
                                Max characters : 255
                              </span>
                              <ErrorMessage
                                component={"div"}
                                name="about"
                                className="errorMsg w-100 text-tiny+"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-end space-x-2">
                    <button
                      type="button"
                      className="btn min-w-[7rem] rounded-full border font-medium text-slate-700 hover:bg-slate-150 focus:bg-slate-150 active:bg-slate-150/80 dark:border-navy-450 dark:text-navy-100 dark:hover:bg-navy-500 dark:focus:bg-navy-500 dark:active:bg-navy-500/90"
                      onClick={() => reset()}>
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="btn min-w-[7rem] rounded-full bg-primary font-medium text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90"
                      disabled={updateProfile.buttonDisable}
                    >
                      {updateProfile.buttonDisable ? <ButtonLoader varient="info" size={5} /> : "Submit"}
                    </button>
                  </div>
                </Form>
              }
            </Formik>
          </div>
        </div>
      </div>
      {/* {showConfirmation ?
        <PasswordModal />
        : null
      } */}
    </>
  );
}

export default BasicProfileTab;